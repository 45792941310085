import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useFormStore = create(
  devtools((set, get) => ({
    // 历史记录页面
    chatHistoryForm: {},
    setChatHistoryForm: (chatHistoryForm) => set({ chatHistoryForm, chatHistorySelectChat: {} }),
    chatHistorySelectChat: {},
    setChatHistorySelectChat: (chatHistorySelectChat) => set({ chatHistorySelectChat }),
    msgHistorySelectMatch: {},
    setMsgHistorySelectMatch: (msgHistorySelectMatch) => set({ msgHistorySelectMatch }),
    msgListParams: {},
    setMsgListParams: (msgListParams) => set(state => ({ msgListParams: {...state.msgListParams, ...msgListParams} })),
    ImageAlbum: [],
    setImageAlbum: (ImageAlbum) => set({ ImageAlbum }),
    ImagePreviewSrc: '',
    setImagePreviewSrc: (ImagePreviewSrc) => set({ ImagePreviewSrc }),

    // 订单跟踪页面
    orderFollowForm: {
      type: 'today',
      // orderStatus: '新状态',
      // orderNumber: '订单号',
      // orderLabel: '订单标签',
      // startDate: '走团时间',
    },
    setOrderFollowForm: (orderFollowForm) => set({ orderFollowForm }),
    orderFollowAdvanceChecked: false,
    setOrderFollowAdvanceChecked: (orderFollowAdvanceChecked) => set({ orderFollowAdvanceChecked }),
  }), { name: 'form-store' })
);
export default useFormStore;
