import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ThemeContext } from '@/stores/ThemeContext'
import AuthApp from '@/views/AuthApp'
import DesktopApp from '@/views/DesktopApp'
import MobileApp from '@/views/MobileApp'
import Standlone from '@/views/Standlone'
import OrderFollow from '@/views/orders/Follow'
import ChatHistory from '@/views/ChatHistory'
import DingdingCallback from '@/views/dingding/Callback'
import DingdingLogout from '@/views/dingding/Logout'
import AccountProfile from '@/views/accounts/Profile'
import ErrorPage from '@/components/ErrorPage'
import ChatWindow from '@/views/ChatWindow'
import MobileConversation from '@/views/mobile/Conversation'
import MobileChat from '@/views/mobile/Chat'
import CallCenter from '@/views/CallCenter'
import MobileSecondHeader from '@/views/mobile/SecondHeaderWrapper'
import CustomerProfile from '@/views/Conversations/Online/order/CustomerProfile'
import SnippetList from '@/views/accounts/SnippetList'
import GeneratePayment from '@/views/accounts/GeneratePayment'

import Unassign from '@/views/ChatUnassign'
import ChatAssign from '@/views/Conversations/ChatAssign'

import DingdingLogin from '@/views/dingding/Login'
import DingdingQRCode from '@/views/dingding/QRCode'
import DingdingAuthCode from '@/views/dingding/AuthCode'
import useAuthStore from '@/stores/AuthStore'
import '@/assets/index.css'

useAuthStore.getState().loadUserSession()

const isMobileApp =
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  ) !== null

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthApp />,
    errorElement: <ErrorPage />,
    children: isMobileApp
      ? [
          {
            element: <MobileApp />,
            children: [
              { index: true, element: <MobileConversation /> },
              { path: 'm/conversation', element: <MobileConversation /> },
              { path: 'unassign', element: <Unassign /> },
            ],
          },
          { path: 'chat/unassign/:whatsappid', element: <ChatAssign /> },
          { path: 'm/chat/:order_sn', element: <MobileChat /> },
          { path: 'm/chat', element: <MobileChat /> },
          {
            element: <MobileSecondHeader />,
            children: [
              { path: 'm/order', element: <CustomerProfile /> },
              { path: 'callcenter/call', element: <CallCenter /> },
              { path: 'callcenter/call/:phonenumber', element: <CallCenter /> },
            ],
          },
        ]
      : [
          {
            element: <DesktopApp />,
            children: [
              { index: true, element: <OrderFollow /> },
              { path: 'order/follow', element: <OrderFollow /> },
              { path: 'chat/history', element: <ChatHistory /> },
              { path: 'order/chat/:order_sn', element: <ChatWindow /> },
              { path: 'order/chat', element: <ChatWindow /> },
              { path: 'account/snippet-list', element: <SnippetList /> },
              { path: 'account/generate-payment', element: <GeneratePayment /> },

              { path: 'account/profile', element: <AccountProfile /> },
              { path: 'chat/unassign/:whatsappid', element: <ChatAssign /> },
              { path: 'chat/unassign', element: <Unassign /> },
              { path: 'callcenter/call', element: <CallCenter /> },
              { path: 'callcenter/call/:phonenumber', element: <CallCenter /> },
            ],
          },
        ],
  },
  {
    path: '/p',
    element: <Standlone />,
    errorElement: <ErrorPage />,
    children: [
      { path: 'dingding/login', element: <DingdingLogin /> },
      { path: 'dingding/logout', element: <DingdingLogout /> },
      { path: 'dingding/callback', element: <DingdingCallback /> },
      { path: 'dingding/qr-code', element: <DingdingQRCode /> },
      { path: 'dingding/auth-code', element: <DingdingAuthCode /> },
    ],
  },
])

const root = document.getElementById('root')
if (!root) throw new Error('No root element found')

createRoot(root).render(
  <React.Suspense
    fallback={
      <div className="flex h-screen w-screen items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={'animate-spin h-24 w-24 text-slate-600'}
          >
            <path d="M21 12a9 9 0 1 1-6.219-8.56" />
          </svg>
          <span className="sr-only">Loading</span>
      </div>
    }
  >
    <ThemeContext.Provider value={{ colorPrimary: '#1ba784', borderRadius: 4 }}>
      <RouterProvider
        router={router}
        fallbackElement={() => <div>Loading...</div>}
      />
    </ThemeContext.Provider>
  </React.Suspense>,
)
