import { createContext, useEffect, useState } from 'react'
import { Flex } from 'antd'
import InputTemplate from './Template'
import InputEmoji from './Emoji'
import InputMediaUpload from './MediaUpload'
import PaymentlinkBtn from './PaymentlinkBtn'
import SnippestBtn from './SnippestBtn'
import useConversationStore from '@/stores/ConversationStore'
import { isEmpty } from '@/utils/commons'

const ComposerTools = ({ channel, invokeSendUploadMessage, invokeSendMessage, invokeUploadFileMessage, inputEmoji, ...props }) => {
  const websocket = useConversationStore((state) => state.websocket)
  const websocketOpened = useConversationStore((state) => state.websocketOpened)
  const currentConversation = useConversationStore((state) => state.currentConversation)
  const talkabled = !isEmpty(currentConversation.sn) && websocketOpened

  return (
    <>
      <Flex gap={4} className='*:text-primary *:rounded-none items-center'>
        {['waba', 'wai'].includes(channel) && <InputTemplate key='templates' disabled={!talkabled} invokeSendMessage={invokeSendMessage} channel={channel} />}

        <InputEmoji key='emoji' disabled={!talkabled} inputEmoji={inputEmoji} />

        {['waba', 'wa', 'wai', 'whatsapp'].includes(channel) && <InputMediaUpload key={'addNewMedia'} disabled={!talkabled} {...{ invokeUploadFileMessage, invokeSendUploadMessage }} />}

        <PaymentlinkBtn />
        <SnippestBtn />

        {/* <Button type='text' className='' icon={<YoutubeOutlined />} size={'middle'} />
        <Button type='text' className='' icon={<AudioOutlined />} size={'middle'} /> */}
      </Flex>
    </>
  )
}
export default ComposerTools
