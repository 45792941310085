import { useCallback, useState, useEffect } from 'react';
import { Divider, Layout, Flex, Image } from 'antd';
import useFormStore from '@/stores/FormStore';
import SearchForm from './Conversations/History/SearchForm';
import ConversationsList from './Conversations/History/ConversationsList';
import MessagesMatchList from './Conversations/History/MessagesMatchList';
import MessagesList from './Conversations/History/MessagesList';
import ImageAlbumPreview from './Conversations/History/ImageAlumPreview';
import { flush, pick } from '@/utils/commons';
import { fetchConversationsSearch, CONVERSATION_PAGE_SIZE } from '@/actions/ConversationActions';
import EmailDetail from './Conversations/Online/Components/EmailDetail';

const { Sider, Content } = Layout;

const Index = (props) => {
  const [formValues, setFormValues] = useFormStore((state) => [state.chatHistoryForm, state.setChatHistoryForm]);
  const [selectedConversation, setSelectedConversation] = useFormStore((state) => [state.chatHistorySelectChat, state.setChatHistorySelectChat]);

  const [conversationsListLoading, setConversationsListLoading] = useState(false);
  const [conversationsList, setConversationsList] = useState([]);

  const [pageParam, setPageParam] = useState({ lasttime: '', loadNextPage: true });

  const handleSubmit = useCallback((values) => {
    setFormValues({ ...values });
    setPageParam({ lasttime: '', loadNextPage: true });
    setConversationsList([]);
  }, []);

  useEffect(() => {
    getConversationsList();
    return () => {};
  }, [formValues]);

  const getConversationsList = async () => {
    setConversationsListLoading(true);
    const params = flush(pick(formValues, ['opisn', 'whatsapp_id', 'search', 'from_date', 'end_date', 'coli_id', 'lasttime', 'mtype', 'mchannel']));
    const data = await fetchConversationsSearch({ ...params, ...pageParam, pagesize: CONVERSATION_PAGE_SIZE });
    setConversationsListLoading(false);
    setConversationsList(conversationsList.concat(data));
    setPageParam({
      lasttime: data.length > 0 ? data[data.length - 1].lasttime : '',
      loadNextPage: !(data.length === 0 || data.length < CONVERSATION_PAGE_SIZE),
    });
    if (data.length === 1) {
      setSelectedConversation(data[0]);
    }
  };

  const [openEmailDetail, setOpenEmailDetail] = useState(false);
  const [emailDetail, setEmailDetail] = useState({});
  const onOpenEmail = (emailMsg) => {
    setOpenEmailDetail(true);
    setEmailDetail({...emailMsg, order_opi: Number(selectedConversation?.opi_sn || 0)});
  }

  return (
    <>
      <SearchForm onSubmit={handleSubmit} initialValues={formValues} />
      <Divider plain orientation='left' className='mb-0'></Divider>
      <Layout hasSider className='h-screen chathistory-wrapper chatwindow-wrapper' style={{ maxHeight: 'calc(100% - 262px)', height: 'calc(100% - 262px)' }}>
        <Sider width={300} theme={'light'} className='h-full overflow-y-auto overflow-x-hidden' style={{ maxHeight: 'calc(100vh - 262px)', height: 'calc(100vh - 262px)' }}>
          <ConversationsList {...{ conversationsListLoading, conversationsList, selectedConversation, handleChatItemClick: setSelectedConversation, onLoadMore: getConversationsList, loadMoreVisible: pageParam.loadNextPage }} />
        </Sider>
        <Content style={{ maxHeight: 'calc(100vh - 262px)', height: 'calc(100vh - 262px)', minWidth: '360px' }}>
          <Flex className='h-full relative'>
            <MessagesMatchList />
            <MessagesList onOpenEmail={onOpenEmail} />
          </Flex>
          <ImageAlbumPreview />
          <EmailDetail open={openEmailDetail} setOpen={setOpenEmailDetail} emailMsg={emailDetail} key={`history-email-detail-${emailDetail.id}`} disabled />
        </Content>
      </Layout>
    </>
  );
};
export default Index;
