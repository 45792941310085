import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { fetchJSON } from '@/utils/request'
import { isEmpty, isNotEmpty } from '@/utils/commons'
import { API_HOST, BUILD_VERSION } from '@/config'
import { usingStorage } from '@/utils/usingStorage';

export const PERM_MERGE_CONVERSATION = 'merge-conversation'
export const PERM_ASSIGN_NEW_CONVERSATION = 'assign-new-conversation'
export const PERM_USE_EMAL = 'use-email'
export const PERM_USE_WHATSAPP = 'use-whatsapp'
export const PERM_IMPORT_EMAIL = 'import-email'

const WAI_SERVER_KEY = 'G-STR:WAI_SERVER'

const useAuthStore = create(devtools((set, get) => ({
  loginUser: {
    userId: -1,
    userIdStr: '-1',
    username: '',
    avatarUrl: '',
    mobile: '',
    email: '',
    openId: '',
    accountList: [],
    emailList: [],
    whatsAppBusiness: '',
    accountName: '',
  },

  loginStatus: 0,

  isPermitted: (perm) => {
    const { waiServer } = usingStorage(WAI_SERVER_KEY)
    const { loginUser } = get()

    if (perm === PERM_USE_WHATSAPP) {
      return isNotEmpty(waiServer) // ['370', '143', '495', '404', '383', '227'].includes(loginUser.userId)
    }

    if (perm === PERM_USE_EMAL) {
      return true//['501', '466', '599', '495', '143', '370', '639', '513', '654', '404', '383', '227'].includes(loginUser.userId)
    }

    // 导入邮件消息，需要配置才能使用
    if (perm === PERM_IMPORT_EMAIL && window.localStorage.getItem('PERM_IMPORT_EMAIL')) {
      return true
    }

    if (perm === PERM_MERGE_CONVERSATION) {
      return ['404', '383', '227'].includes(loginUser.userId)
    }

    if (perm === PERM_ASSIGN_NEW_CONVERSATION) {
      return ['79', '383', '404', '227'].includes(loginUser.userId)
    }
    // 以上是 Hardcode 判断
    // 动态权限列表参考海外供应商平台实现
  },

  login: async (authCode) => {
    const { setStorage } = usingStorage()
    const { saveUserSession, setLoginStatus } = get()

    setLoginStatus(200)

    const json = await fetchJSON(
      'https://p9axztuwd7x8a7.mycht.cn/dingtalk/dingtalkwork/WhatsAppAuth',
      { authCode },
    )

    if (json.errcode === 0 && isNotEmpty(json.result.opisn)) {
      // TODO：保存个人 WhatsApp 服务器地址
      const waiServer = json.result.whatsappinfo.length > 0 ? json.result.whatsappinfo[0].wai_server : ''
      setStorage('G-STR:WAI_SERVER', waiServer)
      set(() => ({
        loginUser: {
          userId: json.result.opisn,
          userIdStr: json.result?.accountlist
            .map((acc) => {
              return acc.OPI_SN
            })
            .join(','),
          emailList: json.result?.emaillist.map(item => {
            return {
              opi_sn: item.opi_sn,
              mat_sn: item.mat_sn,
              email: item.email,
              default: item.Isdefaultemail == 1,
              backup: item.Isbakemail == 1,
            }
          }),
          whatsAppBusiness: json.result.whatsappinfo.length > 0 ? json.result.whatsappinfo[0].whatsapp_waba : '',
          whatsAppNo: json.result.whatsappinfo.length > 0 ? json.result.whatsappinfo[0].whatsapp_wa : '',
          accountName: json.result.opicode,
          username: json.result.nick,
          avatarUrl: json.result.avatarUrl,
          mobile: '+' + json.result.stateCode + '-' + json.result.mobile,
          email: json.result.email,
          openId: json.result.openId,
          accountList: json.result.accountlist,
        },
      }))
      saveUserSession()
      setLoginStatus(302)
    } else {
      setLoginStatus(403)
    }
  },

  getPrimaryEmail: () => {
    const { loginUser } = get()

    const defaultList = loginUser.emailList.filter(email => {
      return email.default
    })

    if (defaultList.length > 0) {
      return defaultList[0].email
    } else {
      const backupList = loginUser.emailList.filter(email => {
        return email.backup
      })

      if (backupList.length > 0) {
        return backupList[0].email
      } else if (loginUser.emailList.length > 0) {
        return loginUser.emailList[0].email
      }
    }
  },

  setLoginStatus: (code) => {
    set(() => ({
      loginStatus: code,
    }))
  },

  logout: () => {
    window.sessionStorage.clear()
    set(() => ({
      loginStatus: 0,
      loginUser: {
        userId: -1,
        userIdStr: '-1',
        username: '',
        avatarUrl: '',
        mobile: '',
        email: '',
        openId: '',
        accountList: [],
        emailList: [],
        whatsAppBusiness: '',
        accountName: '',
      },
    }))
  },

  loadUserSession: () => {
    let sessionData = window.sessionStorage.getItem('GLOBAL_SALES_LOGIN_USER')

    // if (window.location.hostname === '202.103.68.93' && window.location.port === '4173' && isEmpty(sessionData)) {
    //   sessionData = `{"userId":"383","userIdStr":"383,609","emailList":[{"opi_sn":383,"mat_sn":760,"email":"lyj@asiahighlights.com","default":false,"backup":false},{"opi_sn":383,"mat_sn":759,"email":"lyj@chinahighlights.com","default":true,"backup":false},{"opi_sn":383,"mat_sn":758,"email":"lyj@hainatravel.com","default":false,"backup":false}],"username":"廖一军","avatarUrl":"https://static-legacy.dingtalk.com/media/lALPBDDrhXr716HNAoDNAoA_640_640.png","mobile":"+86-18777396951","email":"lyj@hainatravel.com","whatsAppBusiness":"8617458471254","openId":"iioljiPmZ4RPoOYpkFiSn7IKAiEiE","accountList":[{"OPI_SN":383,"OPI_Code":"LYJ","OPI_NameCN":"廖一军","OPI_DEI_SN":7,"OPI_NameEN":"Jimmy Liow"},{"OPI_SN":609,"OPI_Code":"LYJAH","OPI_NameCN":"廖一军（ah）","OPI_DEI_SN":28,"OPI_NameEN":"Jimmy Liow"}]}`

    //   window.localStorage.setItem('GLOBAL_SALES_LOGIN_USER', sessionData)
    // }
    if (import.meta.env.DEV && isEmpty(sessionData)) {
      sessionData = window.localStorage.getItem('GLOBAL_SALES_LOGIN_USER')
    }

    if (sessionData !== null) {
      const sesstionObj = JSON.parse(sessionData)
      set(() => ({
        loginUser: sesstionObj,
      }))
    }
  },

  saveUserSession: () => {
    const { loginUser } = get()
    window.sessionStorage.setItem(
      'GLOBAL_SALES_LOGIN_USER',
      JSON.stringify(loginUser),
    )
  },

  setWhatsAppProfile: async (userId, whatsAppBusiness, whatsAppNo) => {
    const { loginUser, saveUserSession } = get()
    const postWABAUrl = `${API_HOST}/v2/set_whatsapp_info`

    const params = {opi_sn: userId, whatsapp_waba: whatsAppBusiness, whatsapp_wa: whatsAppNo.replace(/\D/g, '')};

    return fetchJSON(postWABAUrl, params)
      .then(json => {
        if (json.errcode === 0) {
          set(() => ({
            loginUser: {
              ...loginUser,
              whatsAppNo: whatsAppNo,
              whatsAppBusiness: whatsAppBusiness,
            }
          }))
          saveUserSession()
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  sendNotify: async () => {
    const { loginUser } = get()
    const notifyUrl = 'https://p9axztuwd7x8a7.mycht.cn/dingtalk/dingtalkwork/SendMDMsgByDingRobotToGroup'

    const params = {
      groupid: 'cidFtzcIzNwNoiaGU9Q795CIg==',
      msgTitle: '有人求助',
      msgText: loginUser.username + '上传了销售平台' + BUILD_VERSION + '的日志'
    };

    return fetchJSON(notifyUrl, params)
      .then(json => {
        if (json.errcode === 0) {
          console.info('发送通知成功')
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },
}), { name: 'authStore' }))

export default useAuthStore
