import { useRouteError } from 'react-router-dom'
import { Result } from 'antd'

export default function ErrorPage() {
  const errorResponse = useRouteError()
  if (import.meta.env.PROD && window.$pageSpy) {
    window.$pageSpy.triggerPlugins('onOfflineLog', 'upload')
  }
  return (
    <Result
      status="404"
      title="Sorry, an unexpected error has occurred."
      subTitle={errorResponse?.message || errorResponse.error?.message}
    />
  )
}
