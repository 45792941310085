import { useRef, useState, useEffect,  } from 'react';
import useConversationStore from '@/stores/ConversationStore';
import { useShallow } from 'zustand/react/shallow';
import { Image, Modal, Button } from 'antd';
import MessagesList from './MessagesList';
import { fetchCleanUnreadMsgCount, fetchMessages, MESSAGE_PAGE_SIZE, UNREAD_MARK } from '@/actions/ConversationActions';
import useAuthStore from '@/stores/AuthStore';
import { useVisibilityState } from '@/hooks/useVisibilityState';
import ConversationNewItem from './ConversationsNewItem';
// import EmailEditor from './Input/bak/EmailEditor';
import EmailEditorPopup from './Input/EmailEditorPopup';
import EmailDetail from './Components/EmailDetail';
import { useOrderStore, } from "@/stores/OrderStore";
import { isEmpty } from '@/utils/commons';
import useStyleStore from '@/stores/StyleStore';

const MessagesWrapper = ({ updateRead = true, forceGetMessages }) => {
  const userId = useAuthStore((state) => state.loginUser.userId);

  const [mobile] = useStyleStore(state => [state.mobile]);
  const [currentConversation, updateCurrentConversation, setCurrentConversation] = useConversationStore((state) => [state.currentConversation, state.updateCurrentConversation, state.setCurrentConversation]);
  // const conversationsList = useConversationStore((state) => state.conversationsList);
  const activeMessages = useConversationStore(useShallow((state) => (state.currentConversation.sn && state.activeConversations[state.currentConversation.sn] ? state.activeConversations[state.currentConversation.sn]: [])));
  // const addToConversationList = useConversationStore((state) => state.addToConversationList);

  const receivedMessageList = useConversationStore((state) => state.receivedMessageList);
  const setMsgLoading = useConversationStore((state) => state.setMsgLoading);

  const refreshTotalNotify = useConversationStore(useShallow((state) => state.refreshTotalNotify));
  const currentConversationSN = useConversationStore(useShallow((state) => state.currentConversation.sn));
  const currentConversationColiSN = useConversationStore(useShallow((state) => state.currentConversation.coli_sn));

	const [orderDetail, customerDetail] = useOrderStore(state => [state.orderDetail, state.customerDetail ]);

  const isVisible = useVisibilityState();

  const [longList, setLongList] = useState([]);
  const [longListLoading, setLongListLoading] = useState(false);
  const [shouldScrollBottom, setShouldScrollBottom] = useState(true);

  const prevSN = useRef(currentConversationSN);
  const prevColiSN = useRef(currentConversationColiSN);

  useEffect(() => {
    if (!mobile) {
      prevSN.current = currentConversationSN
      prevColiSN.current = currentConversationColiSN
    }

    return () => {}
  }, [])


  useEffect(() => {
    // console.log('effect sn', currentConversationSN, prevSN);
    // if (isEmpty(prevSN) || currentConversationSN === prevSN) {
    //   return () => {};
    // }
    // console.log('effect sn', currentConversationSN, prevSN.current, 'llllllllllllllllll');
    // console.log('if fetch', (activeMessages.length))

    if (currentConversationSN && (mobile || prevSN.current !== currentConversationSN) && (activeMessages.length < 20 || forceGetMessages !== undefined)) {
      // console.log('effect sn NOT empty GO fetch', currentConversationSN);
      getFirstPageMessages(currentConversation);
    } else if (currentConversationSN) {
      // console.log('--------------------- set ref longlist', currentConversationSN, currentConversationColiSN)
      prevSN.current = currentConversationSN;
      prevColiSN.current = currentConversationColiSN;
    }
    setShouldScrollBottom(true);
    return () => {};
  }, [currentConversationSN]);

  useEffect(() => {
    // console.log('effect coli_sn', currentConversationSN, currentConversationColiSN, prevColiSN.current, 'xxxxxxxxxxxx');
    // console.log('effect coli_sn After if', currentConversationSN, currentConversationNeedLoaded);

    if (currentConversation.sn && (mobile || prevColiSN.current !== currentConversationColiSN)) {
      // console.log('effect coli_sn NOT empty GO fetch', currentConversationSN);
      getFirstPageMessages({...currentConversation, });
    }
    setShouldScrollBottom(true);
    return () => {};
  }, [currentConversationColiSN]);

  useEffect(() => {
    setLongList(activeMessages);
    const thisLastTime = activeMessages.length > 0 ? activeMessages[0].msgtime : '';
    const loadNextPage = !(activeMessages.length === 0 || activeMessages.length < MESSAGE_PAGE_SIZE);
    updateCurrentConversation({ lasttime: thisLastTime, loadNextPage, });
    return () => {};
  }, [activeMessages, currentConversationSN]);

  useEffect(() => {
    const cleanCurrent = currentConversation.unread_msg_count < UNREAD_MARK; // || currentConversation?.last_message?.actionId; // todo: 取direction, msg_direction === 'outbound' ?? after send
    // if (updateRead === true && isVisible && currentConversation.opi_sn && activeMessages.length > 0) {
    if (updateRead === true && isVisible && currentConversation.opi_sn && cleanCurrent) {
      fetchCleanUnreadMsgCount({ opisn: currentConversation.opi_sn, conversationid: currentConversation.sn });
      updateCurrentConversation({ unread_msg_count: 0 });
      refreshTotalNotify();
    }
    return () => {};
  }, [activeMessages.length, isVisible, currentConversationSN]);


  let refreshing = false;
  const getFirstPageMessages = async (item) => {
    if (refreshing !== false) {
      return false;
    }
    refreshing = true;
    setMsgLoading(true);
    setLongList([]);
    const data = await fetchMessages({
      conversationid: item.sn,
      opisn: forceGetMessages ? item.opi_sn || '' : userId,
      whatsappid: item.whatsapp_phone_number,
      lasttime: '',
      coli_sn: item.coli_sn || '',
    })
    prevSN.current = item.sn;
    prevColiSN.current = item.coli_sn;

    const thisLastTime = data.length > 0 ? data[0].msgtime : ''; // orgmsgtime
    const loadNextPage = !(data.length === 0 || data.length < MESSAGE_PAGE_SIZE);
    updateCurrentConversation({ lasttime: thisLastTime, loadNextPage, });
    // setPrevSN(item.sn);

    setMsgLoading(false);
    receivedMessageList(item.sn, data);
    refreshing = false;
  };

  const getMoreMessages = async () => {
    setShouldScrollBottom(false);
    setLongListLoading(true);
    const data = await fetchMessages({
      conversationid: currentConversation.sn,
      opisn: currentConversation.opi_sn,
      whatsappid: currentConversation.whatsapp_phone_number,
      lasttime: currentConversation?.lasttime || '',
      coli_sn: currentConversation.coli_sn || '',
    })
    setLongListLoading(false);
    setLongList(prevValue => data.concat(prevValue));
    const thisLastTime = data.length > 0 ? data[0].msgtime : '';
    const loadNextPage = !(data.length === 0 || data.length < MESSAGE_PAGE_SIZE);
    updateCurrentConversation({ lasttime: thisLastTime, loadNextPage, });
    return data.length;
  };

  const reference = useRef(null);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewSrc, setPreviewSrc] = useState();
  const onPreviewClose = () => {
    setPreviewSrc('');
    setPreviewVisible(false);
  };
  const handlePreview = (msg) => {
    switch (msg.whatsapp_msg_type) {
      case 'image':
        setPreviewVisible(true);
        setPreviewSrc(msg.data.uri);
        return false;

      case 'document':
        window.open(msg.data.link || msg.data.uri, '_blank', 'noopener,noreferrer');
        return false;

      default:
        return false;
    }
  };

  const [contactsModalVisible, setContactsModalVisible] = useState(false);
  const [contactListData, setContactListData] = useState([]);

  const [newChatModalVisible, setNewChatModalVisible] = useState(false);
  const [newChatFormValues, setNewChatFormValues] = useState({});

  const handleContactItemClick = (contact) => {
    setNewChatFormValues(prev => ({...prev, phone_number: contact.wa_id, name: contact.name }));
    setNewChatModalVisible(true);
    return ;
  }
  const handleContactListClick = (data) => {
    setContactListData(data);
    setContactsModalVisible(true);
  }
  const handleContactClick = (data) => {
    return data.length > 1 ? handleContactListClick(data) : handleContactItemClick(data[0]);
  }
  // EmailEditor

  const [openEmailEditor, setOpenEmailEditor] = useState(false);
  const [fromEmail, setFromEmail] = useState('');
  const [ReferEmailMsg, setReferEmailMsg] = useState('');
  const onOpenEditor = (emailMsgContent) => {
    const { from, to } = emailMsgContent; // msgtext
    // console.log('emailMsgContent', emailMsgContent);

    setOpenEmailEditor(true);
    setFromEmail(to);
    setReferEmailMsg({...emailMsgContent, order_opi: Number(orderDetail?.opi_sn || userId)});
  };

  const [openEmailDetail, setOpenEmailDetail] = useState(false);
  const [emailDetail, setEmailDetail] = useState({});
  const onOpenEmail = (emailMsg) => {
    setOpenEmailDetail(true);
    setEmailDetail({...emailMsg, order_opi: Number(orderDetail?.opi_sn || userId)});
  }

  return (
    <>
      <MessagesList key={`${currentConversationSN}_${currentConversationColiSN}`}
        messages={longList}
        dataSourceLen={longList.length}
        {...{
          reference,
          shouldScrollBottom,
          handlePreview,
          handleContactClick,
          setNewChatModalVisible,
          setNewChatFormValues,
          longListLoading,
          setLongListLoading,
          getMoreMessages,
          loadNextPage: currentConversation?.loadNextPage ?? true,
          onOpenEditor,
          setRefernce: setReferEmailMsg,
          onOpenEmail,
        }}
      />
      <Image
        width={0}
        height={0}
        src={null}
        preview={{
          visible: previewVisible,
          src: previewSrc,
          onClose: onPreviewClose,
          fallback: 'https://hiana-crm.oss-accelerate.aliyuncs.com/WAMedia/afe412d4-3acf-4e79-a623-048aeb4d696a.png',
        }}
      />
      <Modal title='联系人' closable open={contactsModalVisible} onOk={handleContactItemClick} onCancel={() => setContactsModalVisible(false)} footer={null}>
        {contactListData.map((contact) => (
          <Button onClick={() => handleContactItemClick(contact)} type='link' key={contact.id}>
            {contact.name}: <span>{contact.wa_id}</span>
          </Button>
        ))}
      </Modal>
      <ConversationNewItem
        initialValues={{ ...newChatFormValues, is_current_order: false }}
        open={newChatModalVisible}
        onCreate={() => {
          setNewChatModalVisible(false)
          setContactsModalVisible(false)
        }}
        onCancel={() => setNewChatModalVisible(false)}
      />
      {/* <EmailEditor open ={openEmailEditor} setOpen={setOpenEmailEditor} reference={ReferEmailMsg} setRefernce={setReferEmailMsg} {...{ fromEmail, }} key={'email-editor-reply'} /> */}
      <EmailEditorPopup
        open={openEmailEditor}
        setOpen={setOpenEmailEditor}
        fromEmail={fromEmail}
        fromUser={Number(orderDetail?.opi_sn || userId)}
        fromOrder={currentConversation.coli_sn}
        oid={orderDetail?.order_no}
        customerDetail={customerDetail}
        quoteid={ReferEmailMsg.mai_sn || ReferEmailMsg.id}
        conversationid={currentConversation.sn}
        key={`email-msg-reply-top-popup_${ReferEmailMsg.id}`}
      />
      <EmailDetail open={openEmailDetail} setOpen={setOpenEmailDetail} emailMsg={emailDetail} key={`email-detail-${emailDetail.id}`} />
    </>
  )
};
export default MessagesWrapper;
