import { useState, useEffect, useCallback } from 'react'
import { isEmpty } from '@/utils/commons'
import { getEmailDetailAction, postResendEmailAction, getSalesSignatureAction, getEmailOrderAction } from '@/actions/EmailActions'
import { App } from 'antd'
import useConversationStore from '@/stores/ConversationStore';
import { msgStatusRenderMapped } from '@/channel/bubbleMsgUtils';

export const useEmailSignature = (opi_sn) => {

  const [signature, setSignature] = useState('')

  const getSignature = useCallback(async () => {
    if (isEmpty(opi_sn)) {
      return false
    }
    try {
      const data = await getSalesSignatureAction({ opi_sn })
      setSignature(data)
    } catch (err) {
      console.error(err)
    }
  }, [opi_sn])

  useEffect(() => {
    getSignature()
  }, [opi_sn])

  return { signature, getSignature }

}

/**
 * @param mai_sn 邮件编号ID
 * @param data 直接传递, 不重复获取
 * * 在详情点击`回复`呼出编辑时
 */
export const useEmailDetail = (mai_sn, data) => {
  const {notification} = App.useApp()
  const [loading, setLoading] = useState(false)
  const [mailData, setMailData] = useState({ loading, info: {}, content: '', attachments: [] })
  const [coliSN, setColiSN] = useState('');
  const [orderDetail, setOrderDetail] = useState({});

  const [updateMessageItem] = useConversationStore(state => [state.updateMessageItem]);

  useEffect(() => {
    const getEmailDetail = async () => {
      if (isEmpty(mai_sn)) {
        return false
      }
      try {
        setLoading(true)
        const data = await getEmailDetailAction({ mai_sn })
        setMailData(data)
        setColiSN(data.info.MAI_COLI_SN)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        notification.error({
          message: "请求失败",
          description: err.message || '网络异常',
          placement: "top",
          duration: 3,
        });
      }
    }

    if (isEmpty(data)) getEmailDetail()
    else setMailData(data)
  }, [mai_sn])


  useEffect(() => {
    const getOrderDetail = async () => {
      if (isEmpty(coliSN)) {
        return false
      }
      try {
        setLoading(true)
        const data = await getEmailOrderAction({ colisn: coliSN })
        setOrderDetail(data)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        notification.error({
          message: "请求失败",
          description: err.message || '网络异常',
          placement: "top",
          duration: 3,
        });
      }
    }

    getOrderDetail()
  }, [coliSN])

  const postEmailResend = async ({ mai_sn, conversationid: externalid, actionId: actionid, ...body }) => {
    if (isEmpty(mai_sn)) {
      return false
    }
    await postResendEmailAction({ mai_sn, externalid, actionid, token: 0 })
    // 重发没有状态返回值, 此处前端处理为'待发送', todo: 刷新页面后消息仍为上一个状态'失败'
    updateMessageItem({ conversationid: externalid, actionid, id: actionid, status: msgStatusRenderMapped['accepted'] })
  }

  return { loading, mailData, orderDetail, postEmailResend }
}

export const EmailBuilder = ({subject, content}) => {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html lang="en"><head><title>${subject}</title><meta http-equiv="Content-Type" content="text/html charset=UTF-8" /><meta content="width=device-width" name="viewport"><meta charset="UTF-8"><meta content="IE=edge" http-equiv="X-UA-Compatible"><meta content="width=device-width" name="viewport"><meta charset="UTF-8"><meta content="IE=edge" http-equiv="X-UA-Compatible"><meta content="telephone=no,address=no,email=no,date=no,url=no" name="format-detection"><meta content="light" name="color-scheme"><meta content="light" name="supported-color-schemes"><style id="font">body#highlights-email{ font-family: Verdana, sans-serif;} table{ border-collapse: collapse; border-spacing: 0;} </style></head><body id="highlights-email" style="margin: 0 auto; padding: undefined; width: 900px; background-color: #fcfcfc;">${content}</body></html>`;
}
