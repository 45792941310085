import { useState, useEffect } from 'react'
import LexicalEditor from '@/components/LexicalEditor'
import { isEmpty } from '@/utils/commons';

/**
 * 封装的编辑组件, 用于在Form.Item 中使用
 */
const LexicalEditorInput = (props) => {
  const { id, value = {}, onChange } = props

  const [defaultHtml, setDefaultHtml] = useState('')

  useEffect(() => {
    if (typeof value === 'string') {
      setDefaultHtml(value)
    }

    return () => {}
  }, [value])

  /**
   * 触发onChange
   * changedValue: { editorStateJSON, htmlContent, textContent }
   */
  const triggerChange = (changedValue) => {
    onChange?.({
      ...changedValue,
    })
  }

  return (
    <LexicalEditor
      id={id}
      {...{ isRichText: true }}
      onChange={(val) => {
        triggerChange(val)
      }}
      defaultValue={defaultHtml}
    />
  )
}

export default LexicalEditorInput
