import { createContext, useEffect, useState } from 'react'
import useSnippetStore from '@/stores/SnippetStore'

import { Tooltip, Button } from 'antd'

const SnippestBtn = ({ ...props }) => {
  const [openSnippetDrawer] = useSnippetStore((state) => [state.openDrawer])

  return (
    <Tooltip title='图文集'>
      <Button type='text' onClick={() => openSnippetDrawer()} size={'middle'} className='px-1'>
        📝
      </Button>
    </Tooltip>
  )
}
export default SnippestBtn
