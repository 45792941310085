import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { fetchJSON, postForm } from '@/utils/request'
import { API_HOST, EMAIL_HOST } from '@/config'
import { isNotEmpty, prepareUrl } from '@/utils/commons'

const initialState = {
  orderList: [],
  orderDetail: {},
  customerDetail: {},
  lastQuotation: {},
  quotationList: [],
  otherEmailList: [],
};

export const useOrderStore = create(devtools((set, get) => ({

  ...initialState,
  drawerOpen: false,

  resetOrderStore: () => set(initialState),

  openDrawer: () => {
    set(() => ({
      drawerOpen: true
    }))
  },

  closeDrawer: () => {
    set(() => ({
      drawerOpen: false
    }))
  },

  fetchOrderList: async (formValues, loginUser) => {
    let fetchOrderUrl = `${API_HOST}/getwlorder?opisn=${loginUser.userIdStr}&otype=${formValues.type}`
    const params = {};

    if (formValues.type === 'advance') {
      fetchOrderUrl = `${API_HOST}/getdvancedwlorder?opisn=${loginUser.userIdStr}`;
      const { type, ...formParams } = formValues;
      Object.assign(params, formParams)
    }

    return fetchJSON(fetchOrderUrl, params)
      .then(json => {
        if (json.errcode === 0) {
          set(() => ({
            orderList: json.result.map((order) => { return { ...order, key: order.COLI_ID } }),
          }))
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })

  },

  fetchOrderDetail: (colisn) => {
    return fetchJSON(`${API_HOST}/getorderinfo`, { colisn })
      .then(json => {
        if (json.errcode === 0 && json.result.length > 0) {
          const orderResult = json.result[0]
          set(() => ({
            orderDetail: {...orderResult, coli_sn: colisn },
            customerDetail: orderResult.contact.length > 0 ? orderResult.contact[0] : {},
            lastQuotation: orderResult.quotes.length > 0 ? orderResult.quotes[0] : {},
            quotationList: orderResult.quotes,
          }))
          return {
            orderDetail: {...orderResult, coli_sn: colisn },
            customerDetail: orderResult.contact.length > 0 ? orderResult.contact[0] : {},
            lastQuotation: orderResult.quotes.length > 0 ? orderResult.quotes[0] : {},
            quotationList: orderResult.quotes,
          }
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })

  },

  appendOrderComment: async (opi_sn, coli_sn, comment) => {
    const { fetchOrderDetail } = get()
    const formData = new FormData()
    formData.append('opi_sn', opi_sn)
    formData.append('coli_sn', coli_sn)
    formData.append('remark', comment)

    return postForm(`${API_HOST}/remark_order`, formData)
      .then(json => {
        if (json.errcode === 0) {
          return fetchOrderDetail(coli_sn)
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  generatePayment: async (formValues) => {
    const formData = new FormData()
    formData.append('descriptions', formValues.description)
    formData.append('currency', formValues.currency)
    formData.append('lgc', formValues.langauge)
    formData.append('amount', formValues.amount)
    formData.append('coli_id', formValues.orderNumber)
    formData.append('wlemail', formValues.notifyEmail)
    formData.append('ordertype', formValues.orderType)
    formData.append('opisn', formValues.userId)
    formData.append('paytype', 'SYT')
    formData.append('wxzh', 'cht')
    formData.append('fq', 0)
    formData.append('onlyusa', 0)
    formData.append('useyhm', 0)

    return postForm(`${API_HOST}/generate_payment_links`, formData)
      .then(json => {
        if (json.errcode === 0) {
          return json.result
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  fetchHistoryOrder: (userId, email, whatsappid='') => {
    return fetchJSON(`${API_HOST}/query_guest_order`, { opisn: userId, whatsappid, email: email })
      .then(json => {
        if (json.errcode === 0) {
          return json.result
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })

  },

  importEmailMessage: ({ orderId, orderNumber }) => {
    return fetchJSON(`${API_HOST}/generate_email_msg`, { coli_sn: orderId, coli_id: orderNumber })
      .then(json => {
        if (json.errcode === 0) {
          return json
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  batchImportEmailMessage: () => {
    const { orderList } = get()

    const orderPromiseList = orderList.map(order => {
      return new Promise((resolve, reject) => {
        fetchJSON(`${API_HOST}/generate_email_msg`, { coli_sn: order.COLI_SN, coli_id: order.COLI_ID })
          .then(json => {
            if (json.errcode === 0) {
              resolve(json)
            } else {
              reject(json?.errmsg + ': ' + json.errcode)
            }
          })
      })
    })

    Promise.all(orderPromiseList).then((values) => {
      console.log(values);
    })
  },

  fetchOtherEmail: (coli_sn) => {
    return fetchJSON(`${EMAIL_HOST}/email_supplier`, { coli_sn })
      .then(json => {
        if (json.errcode === 0) {
          set(() => ({
            otherEmailList: json.result.MailInfo ?? [],
          }))
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  setOrderPropValue: async (colisn, propName, value) => {

    if (propName === 'orderlabel') {
      set((state) => ({
        orderDetail: {
          ...state.orderDetail,
          tags: value
        }
      }))
    }

    if (propName === 'orderstatus') {
      set((state) => ({
        orderDetail: {
          ...state.orderDetail,
          states: value
        }
      }))
    }

    return fetchJSON(`${API_HOST}/setorderstatus`, { colisn, stype: propName, svalue: value })
      .then(json => {
        if (json.errcode > 0) {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

}), { name: 'orderStore' }))

export const OrderLabelDefaultOptions = [
  { value: 240003, label: '重点', emoji: '❣️' },
  { value: 240002, label: '次重点', emoji: '❗' },
  { value: 240001, label: '一般', emoji: '' }
]
export const OrderLabelDefaultOptionsMapped = OrderLabelDefaultOptions.reduce((acc, cur) => {
  return { ...acc, [String(cur.value)]: cur }
}, {}) ;

export const OrderStatusDefaultOptions = [
  { value: 1, label: '新订单', emoji: '' },
  { value: 2, label: '报价中', emoji: '📝' },
  { value: 3, label: '以后联系', emoji: '💤' },
  { value: 4, label: '等待付订金', emoji: '🛒' },
  { value: 5, label: '成行', emoji: '💰' },
  { value: 6, label: '丢失', emoji: '🎈' },
  { value: 7, label: '取消', emoji: '🚫' },
  { value: 8, label: '未报价', emoji: '' }
]
export const OrderStatusDefaultOptionsMapped = OrderStatusDefaultOptions.reduce((acc, cur) => {
  return { ...acc, [String(cur.value)]: cur }
}, {})

export const RemindStateDefaultOptions = [
  { value: '1', label: '一催' },
  { value: '2', label: '二催' },
  { value: '3', label: '三催' }
]
