import { ReactNode } from 'react'

export type ConditionalProps = {
  /**
   * the condition to test against
   */
  condition: boolean
  /**
   * the component to render when condition is true
   */
  whenTrue: ReactNode
  /**
   * the component to render when condition is false
   */
  whenFalse: ReactNode
}

export function Conditional({
  condition,
  whenFalse,
  whenTrue,
}: ConditionalProps) {
  return condition ? whenTrue : whenFalse
}
