import { Children, createContext, useEffect, useState } from 'react';
import { Dropdown, Space, Tabs } from 'antd';
import { MailFilled, MailOutlined, WhatsAppOutlined, DownOutlined } from '@ant-design/icons';
import InputComposer from './Input/InputComposer';
import EmailComposer from './Input/EmailComposer';
import { WABIcon } from '@/components/Icons';
import useConversationStore from '@/stores/ConversationStore';
import { useShallow } from 'zustand/react/shallow';
import useStyleStore from '@/stores/StyleStore';
import { isEmpty } from '@/utils/commons';
import { DEFAULT_CHANNEL } from '@/config';
import { WABAccounts, WABAccountsMapped } from '@/channel/bubbleMsgUtils';
import useAuthStore, { PERM_USE_WHATSAPP } from '@/stores/AuthStore';

/**
 * @ignore
 * 不在此处配置, 在个人档案页面配置
 */
const WABASwitcher = ({ onSelect, }) => {
  const [pickV, setPickV] = useState({}); // review: 全局管理? 后端管理?
  return (
    <Dropdown
    // trigger={['contextMenu', 'hover']}
      selectable
      menu={{
        items: WABAccounts.map(ele => ({ key: ele.phoneNumber, label: ele.verifiedName })),
        onClick: ({ key, domEvent, ...x }) => {
          domEvent.stopPropagation();
          setPickV(WABAccountsMapped[key]);
          onSelect?.(WABAccountsMapped[key]);
        },
      }}>
      <Space>
        {/* <WABIcon /> */}
        {pickV.label || 'WABA'}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

const ReplyWrapper = () => {
  const [mobile] = useStyleStore(state => [state.mobile]);
  const {userId, whatsAppBusiness} = useAuthStore((state) => state.loginUser);
  const [isPermitted] = useAuthStore((state) => [state.isPermitted])

  const [activeChannel, setActiveChannel] = useState(DEFAULT_CHANNEL);
  const onChannelTabsChange = (activeKey) => {
    setActiveChannel(activeKey);
  };

  const [lastWABAMsg, setLastWABAMsg] = useState({});

  const [referenceMsg] = useConversationStore((state) => [state.referenceMsg]);
  const [sessionType] = useConversationStore((state) => [state.currentConversation.session_type]);
  const activeMessages = useConversationStore(
    useShallow((state) => (state.currentConversation.sn && state.activeConversations[state.currentConversation.sn] ? state.activeConversations[state.currentConversation.sn] : []))
  );
  useEffect(() => {
    const len = activeMessages.length;
    const thisLastChannel = activeMessages.length > 0 ? activeMessages[len - 1]?.msg_source : DEFAULT_CHANNEL;
    const channel = thisLastChannel === 'email' ? 'email' : DEFAULT_CHANNEL;
    setActiveChannel((thisLastChannel || DEFAULT_CHANNEL).toLowerCase());

    // console.time('lastWABAMsg');
    const lastInWABAMsgI = activeMessages.findLastIndex(ele => ele.msg_source === 'WABA' && ele.msg_direction=== 'inbound' && ele.status !== 'failed');
    const lastWABAMsg = lastInWABAMsgI !== -1 ? activeMessages[lastInWABAMsgI] : {};
    setLastWABAMsg(lastWABAMsg);
    // console.timeEnd('lastWABAMsg')

    return () => {};
  }, [activeMessages]);

  useEffect(() => {
    if (!isEmpty(referenceMsg.msg_source)) {
      setActiveChannel((referenceMsg.msg_source).toLowerCase())
    }
    return () => {}
  }, [referenceMsg.msg_source])


  const replyTypes = [
    // { key: 'waba', label: mobile ? '' : (<WABASwitcher />), icon: <WABIcon />, children: <InputComposer channel={'waba'} /> },
    {
      key: 'waba', disabled: sessionType === 1,
      label: mobile ? '' : `商业号(${WABAccountsMapped[whatsAppBusiness]?.verifiedName || '-'})`,
      icon: <WABIcon className={sessionType === 1 ? 'text-gray-500 [&_path]:fill-current' : ''} />,
      children: <InputComposer currentActive={activeChannel === 'waba'} channel={'waba'} lastWABAMsg={lastWABAMsg} />,
    },
    { key: 'email', label: mobile ? '' : '邮件', icon: <MailOutlined className='text-indigo-500' />, children: <EmailComposer currentActive={activeChannel === 'email'} /> },
    {
      key: 'wai',
      label: mobile ? '' : 'WhatsApp',
      icon: <WhatsAppOutlined className='text-whatsapp' />,
      children: isPermitted(PERM_USE_WHATSAPP) ? (
        <InputComposer channel={'wai'} />
      ) : (
        <div className='p-2 py-4 text-center text-whatsapp bg-gray-200 rounded rounded-b-none border-gray-300 border-solid border border-b-0 border-x-0'>请联系你的主管申请开通</div>
      ),
    },
    // {
    //   key: 'wa',
    //   label: mobile ? '' : 'WhatsApp',
    //   icon: <WhatsAppOutlined className='text-whatsapp' />,
    //   children: <div className='p-2 py-4 text-center text-whatsapp bg-gray-200 rounded rounded-b-none border-gray-300 border-solid border border-b-0 border-x-0'>正在开发……敬请期待</div>,
    // },
    // TODO: ⬆️WA未登录，要提示用户到个人页面扫码
  ]

  return (
    <div className='reply-wrapper rounded rounded-b-none emoji bg-white'>
      <Tabs activeKey={activeChannel} onChange={onChannelTabsChange} type={'card'} size={'small'} tabPosition={'bottom'} className='bg-white *:m-0 ' items={replyTypes} />
    </div>
  );
};

export default ReplyWrapper;
