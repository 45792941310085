import { Flex, Result, Spin } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isNotEmpty } from '@/utils/commons'
import * as dd from 'dingtalk-jsapi'

// 获取微应用免登授权码
// https://open.dingtalk.com/document/orgapp/jsapi-request-auth-code
function Login() {
  const navigate = useNavigate()
  const urlSearch = new URLSearchParams(location.search)
  const originUrl = urlSearch.get('origin_url')
  const redirectUrl = isNotEmpty(originUrl)
    ? 'https://login.dingtalk.com/oauth2/auth?redirect_uri=https%3A%2F%2Fsales.mycht.cn%2Fp%2Fdingding%2Fcallback%3Forigin_url%3D' +
      originUrl +
      '&response_type=code&client_id=dingwgdx6emlxr3fcrg8&scope=openid&state=global-saels&prompt=consent'
    : 'https://login.dingtalk.com/oauth2/auth?redirect_uri=https%3A%2F%2Fsales.mycht.cn%2Fp%2Fdingding%2Fcallback%3Forigin_url%3D&response_type=code&client_id=dingwgdx6emlxr3fcrg8&scope=openid&state=global-saels&prompt=consent'

  const [errorMsg, setErrorMsg] = useState('')

  const dingTalkPlatForm = dd.env.platform

  if (import.meta.env.DEV) {
    return (
      <Flex justify='center' align='center' gap='middle' vertical>
        <Result
          status='warning'
          title='你处于开发模式'
          subTitle={'请先设置 Local storage'}
          extra={[<Spin key='dingding-login' size='small' />]}
        />
      </Flex>
    )
  }
  window.location = redirectUrl
  // if (dingTalkPlatForm === 'notInDingTalk') {
  //   window.location = redirectUrl
  // } else {
  //   dd.requestAuthCode({
  //     clientId: 'dingl3jyntkazyg4coxf',
  //     corpId: 'ding48bce8fd3957c96b',
  //     success: (res) => {
  //       const { code } = res
  //       navigate('/p/dingding/callback?state=jsapi-auth&authCode=' + code, {
  //         replace: true,
  //       })
  //     },
  //     fail: (error) => {
  //       setErrorMsg(JSON.stringify(error))
  //     },
  //     complete: () => {},
  //   })
  // }

  return (
    <Flex justify='center' align='center' gap='middle' vertical>
      <Result
        status='success'
        title='欢迎使用'
        subTitle={'正在获取钉钉登授权' + errorMsg}
        extra={[<Spin key='dingding-login' size='small' />]}
      />
    </Flex>
  )
}

export default Login
