import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useStyleStore = create(
  devtools((set, get) => ({
    mobile: false,
    setMobile: (mobile) => set({ mobile }),
  }))
);
export default useStyleStore;
