import { Flex, Result, Input, Button } from 'antd'
import { useState, useEffect } from 'react'
import * as dd from 'dingtalk-jsapi'

// 获取微应用免登授权码
// https://open.dingtalk.com/document/orgapp/jsapi-request-auth-code
function AuthCode() {

  const [result, setResult] = useState('')
  const [clientValue, setClientValue] = useState('dingl3jyntkazyg4coxf')
  const handleRequest = () => {
    dd.requestAuthCode({
      clientId: clientValue,
      corpId: 'ding48bce8fd3957c96b',
      success: (res) => {
        const { code } = res
        setResult(code)
      },
      fail: (error) => {
        setResult(JSON.stringify(error))
      },
      complete: () => {},
    })
  }

  useEffect(() => {
    const dingTalkPlatForm = dd.env.platform
    setResult(dingTalkPlatForm)
  }, [])

  return (
    <Flex justify='center' align='center' gap='middle' vertical>
      <Result
        status='success'
        title={clientValue}
        subTitle={result}
      />
      <Input value={clientValue} onChange={e => setClientValue(e.currentTarget.value)} />
      <Button type='primary' onClick={() => handleRequest()}>请求</Button>
    </Flex>
  )
}

export default AuthCode
