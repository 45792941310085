import { memo } from 'react';
import { App } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { MessageBox } from 'react-chat-elements';
import { isEmpty, } from '@/utils/commons';
import { useEmailDetail,  } from '@/hooks/useEmail';

const BubbleEmail = ({ onOpenEditor, onOpenEmail, ...message  }) => {
  const { notification } = App.useApp()

  const RenderText = memo(function renderText({ className, email, sender }) {
    return (
      <div onClick={() => handlePreview(message)} className={`text-sm leading-5 emoji-text whitespace-pre-wrap cursor-pointer  ${className}`} key={'msg-text'}>
        {/* {sender === 'me' && <div><b>From: </b>{email.from}</div>} */}
        {/* <div><b>To: </b>{email.to}</div> */}
        <div ><b>Subject: </b>{email.subject}</div>
        <hr className='border-0 border-solid border-b border-neutral-400'/>
        <div className='line-clamp-2 text-neutral-600'>{email.abstract}</div>
        <div className=' text-neutral-500 text-xs italic'>{email.coli_id ? `订单: ${email.coli_id}` : '未绑定订单'}</div>
        </div>
    );
  });
  const handlePreview = (message) => {
    // console.log('handlePreview');
    if (typeof onOpenEmail === 'function') {
      onOpenEmail(message);
    }
  }

  const { postEmailResend } = useEmailDetail()
  const handleResend = async (emailMsgContent) => {
    if (isEmpty((emailMsgContent.mai_sn || emailMsgContent.id))) {
      return false
    }
    try {
      await postEmailResend({ mai_sn: emailMsgContent.mai_sn, conversationid: message.conversationid, actionId: emailMsgContent.actionId })
    } catch (err) {
      notification.error({
        message: "请求失败",
        description: err.message,
        placement: "top",
        duration: 3,
      });
    }
  };
  return (
    <MessageBox
      {...message}
      key={`${message.from}.${message.id}`}
      type='text'
      title={
        <>
          <MailOutlined className='text-indigo-600' />
          <span className={`pl-2 ${message.sender === 'me' ? '' : 'text-indigo-600'}`}>
            {message.sender !== 'me' ?
            <>
            <b>From: </b>
            <span>
              {/* {message?.emailOrigin?.fromName}&nbsp;&lt;{message?.emailOrigin.fromEmail}&gt; */}
              {message.msgOrigin?.from}
            </span>
            </> : <><b>To: </b>{message.msgOrigin?.to}</>
            }
          </span>
        </>
      }
      // titleColor={message.sender !== 'me' ? '#4f46e5' : ''} // 600
      notch={false}
      position={message.reposition || (message.sender === 'me' ? 'right' : 'left')}
      onReplyClick={() => onOpenEditor(message.msgOrigin)}
      onForwardClick={ () => { handleResend(message.msgOrigin); }}
      onOpen={() => handlePreview(message)}
      onTitleClick={() => handlePreview(message)}
      text={<RenderText str={message?.msgOrigin || ''} className={message.status === 'failed' ? 'line-through text-neutral-400' : ''} email={{...message.msgOrigin, coli_id: message.coli_id || message.msgOrigin?.coli_id}} sender={message.sender} />}
      {...(message.sender === 'me'
        ? {
            styles: { backgroundColor: '#e0e7ff', boxShadow: 'none', border: '1px solid #818cf8' }, // 100 400
          }
        : {
          styles: { backgroundColor: '#fff', boxShadow: 'none', border: '1px solid #818cf8' },
        })}
      className={[
        'whitespace-pre-wrap',
        message.sender === 'me' ? 'whatsappme-container' : '',
        message.status === 'failed' ? 'failed-msg' : '',
      ].join(' ')}
    />
  );
};
export default BubbleEmail;
