import { useEffect, useState } from 'react';
import { App, Button, Popover, Tabs, List, Image, Avatar, Card, Flex, Space } from 'antd';
import { FileSearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { RotateLeftOutlined, RotateRightOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { InboxIcon, SendPlaneFillIcon } from '@/components/Icons';
import { groupBy, isEmpty, TagColorStyle as CalColorStyle } from '@/utils/commons';
import { useShallow } from 'zustand/react/shallow';
import EmailDetail from './EmailDetail';
import { MESSAGE_PAGE_SIZE, fetchMessagesHistory } from '@/actions/ConversationActions';
import DnDModal from '@/components/DnDModal';
import useConversationStore from '@/stores/ConversationStore';
import useStyleStore from '@/stores/StyleStore';
import useAuthStore from '@/stores/AuthStore';
import { sentMsgTypeMapped } from '@/channel/bubbleMsgUtils';
import { v4 as uuid } from 'uuid';

const BIG_PAGE_SIZE = MESSAGE_PAGE_SIZE * 10;

const getVideoName = (vUrl) => {
  if (!vUrl) return '';
  const url = new URL(vUrl);
  return url.pathname.split('/').pop();
};
/**
 * 消息记录筛选----------------------------------------------------------------------------------------------------
 */
const MessageListFilter = ({ ...props }) => {
  const websocket = useConversationStore((state) => state.websocket);
  const userId = useAuthStore((state) => state.loginUser.userId);
  const sentOrReceivedNewMessage = useConversationStore((state) => state.sentOrReceivedNewMessage);

  const [mobile] = useStyleStore((state) => [state.mobile]);
  const [openPopup, setOpenPopup] = useState(false);

  const activeMessages = useConversationStore(
    useShallow((state) => (state.currentConversation.sn && state.activeConversations[state.currentConversation.sn] ? state.activeConversations[state.currentConversation.sn] : []))
  );
  const currentConversation = useConversationStore((state) => state.currentConversation);
  const { opi_sn: opisn, whatsapp_phone_number: whatsappid } = currentConversation;

  const { message: appMessage } = App.useApp();

  const LongList = () => {
    return <></>;
  };

  const [loading, setLoading] = useState(false);

  const [paramsForMsgList, setParamsForMsgList] = useState({});
  const [historyMessages, setHistoryMessages] = useState([]);
  const getMessagesPre = async (param) => {
    setLoading(true);
    const chatItem = { opisn, whatsappid };
    const data = await fetchMessagesHistory({ ...chatItem, lasttime: param.pretime, pagedir: 'pre', pagesize: BIG_PAGE_SIZE });
    setLoading(false);
    setHistoryMessages((prevValue) => [].concat(data, prevValue));
    const loadPrePage = !(data.length === 0 || data.length < BIG_PAGE_SIZE);
    // if (data.length > 0) {
    //   setParamsForMsgList({ loadPrePage, pretime: data[0].orgmsgtime });
    // }
    setParamsForMsgList((preVal) => ({ ...preVal, loadPrePage, pretime: data.length > 0 ? data[0].orgmsgtime : preVal.pretime }));
  };
  const onLoadMore = async () => {
    await getMessagesPre(paramsForMsgList);
  };
  const loadMore = paramsForMsgList.loadPrePage ? (
    <div className='text-center h-8 leading-8'>
      {!loading ? (
        <Button onClick={onLoadMore} size='small'>
          loading more
        </Button>
      ) : (
        <LoadingOutlined className='text-primary' />
      )}
    </div>
  ) : null;

  const handleCopyClick = (url) => {
    try {
      navigator.clipboard.writeText(url);
      appMessage.success('复制成功😀');
    } catch (error) {
      appMessage.warning('不支持自动复制, 请手动复制');
    }
  };

  useEffect(() => {
    // if (activeMessages.length > 0) {
    //   setHistoryMessages(activeMessages);
    // }
    setHistoryMessages(activeMessages)
    const { opi_sn: opisn, whatsapp_phone_number: whatsappid } = currentConversation;
    setParamsForMsgList({ loadPrePage: true, pretime: activeMessages.length > 0 ? activeMessages[0].orgmsgtime : '', opisn, whatsappid });

    return () => {};
  }, [activeMessages, currentConversation.sn]);

  const Album = () => {
    const data = historyMessages.filter((item) => item.type === 'photo').reverse();
    const byDate = groupBy(data, (item) => item.localDate.slice(0, 10));

    const [visible, setVisible] = useState(false);
    const handleReSend = (currentIndex) => {
      console.log('handleReSend', currentIndex, data[currentIndex]);
      // fix: 没有先push到窗口上, 导致没有更新
      const item = data[currentIndex];
      const msgObjMerge = {
        sender: 'me',
        senderName: 'me',
        to: currentConversation.whatsapp_phone_number,
        date: new Date(),
        status: 'waiting',
        // ...msgObj,
        data: { link: item.data.uri, dataUri: item.data.uri, uri: item.data.uri, loading: 1 }, // ...fileObj.data,
        id: `${currentConversation.sn}.${uuid()}`,
        type: item.whatsapp_msg_type,
        // name: item.title,
      };
      const contentToRender = sentMsgTypeMapped[item.type].contentToRender(msgObjMerge);
      sentOrReceivedNewMessage(contentToRender.conversationid, contentToRender);

      const contentToSend = sentMsgTypeMapped[item.type].contentToSend(msgObjMerge);
      websocket.sendMessage({ ...contentToSend, opi_sn: userId, coli_sn: currentConversation.coli_sn, conversationid: currentConversation.sn });
      setOpenPopup(false);
      setVisible(false);
    };
    return (
      <>
        <Image.PreviewGroup
          className='my-4'
          preview={{
            visible,
            onVisibleChange: (value) => {
              setVisible(value);
            },
            toolbarRender: (_, { transform: { scale }, actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn }, current }) => (
              <Space size={12} className='toolbar-wrapper text-xl px-6 rounded-full bg-[rgba(0,0,0,.1)] *:px-3'>
                <RotateLeftOutlined onClick={onRotateLeft} className='cursor-pointer hover:opacity-30' />
                <RotateRightOutlined onClick={onRotateRight} className='cursor-pointer hover:opacity-30' />
                <ZoomOutOutlined
                  disabled={scale === 1}
                  onClick={onZoomOut}
                  className={[scale === 1 ? 'cursor-not-allowed opacity-30' : '', 'hover:opacity-30 cursor-pointer'].join(' ')}
                />
                <ZoomInOutlined
                  disabled={scale === 50}
                  onClick={onZoomIn}
                  className={[scale === 50 ? 'cursor-not-allowed opacity-30' : '', 'hover:opacity-30 cursor-pointer'].join(' ')}
                />
                {/* <ShareForwardIcon onClick={() => handleReSend(current)} className='cursor-pointer hover:opacity-30' title='重发' /> */}
              </Space>
            ),
          }}>
          <List
            className='max-h-96 overflow-y-auto'
            itemLayout='vertical'
            dataSource={Object.keys(byDate)}
            loadMore={loadMore}
            loading={loading}
            renderItem={(date) => (
              <List.Item>
                <Card size='small' title={date} key={date} className='mb-2'>
                  <div className={['grid  gap-2 ', mobile === false ? 'grid-cols-5' : 'grid-cols-3'].join(' ')}>
                    {byDate[date].map((img) => (
                      <Image className='border object-cover' key={img.data.id} width={100} height={100} src={img.data.uri} />
                    ))}
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </Image.PreviewGroup>
      </>
    );
  };

  const Videos = () => {
    const data = historyMessages.filter((item) => item.type === 'video').reverse();

    const [videoUrl, setVideoUrl] = useState('');
    const [openVideoPlay, setOpenVideoPlay] = useState(false);
    const handleOpenVideoPlay = (vurl) => {
      setVideoUrl(vurl);
      setOpenVideoPlay(true);
      setOpenPopup(false);
    };

    return (
      <>
        <List
          className='max-h-96 overflow-y-auto'
          // itemLayout='horizontal'
          dataSource={data}
          loadMore={loadMore}
          loading={loading}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar size='small' style={CalColorStyle(item.sender, true)}>
                    {item.senderName}
                  </Avatar>
                }
                title={<span onClick={() => handleOpenVideoPlay(item?.data.videoURL)}>{getVideoName(item?.data.videoURL)}</span>}
                description={
                  <Flex>
                    <div className='flex-auto'>{item.localDate}</div>
                    <Button key='copyv' onClick={() => handleCopyClick(item.data.videoURL)} type='link' size='small'>
                      复制🔗
                    </Button>
                  </Flex>
                }
              />
              {item.text}
            </List.Item>
          )}
        />
        <DnDModal open={openVideoPlay} setOpen={setOpenVideoPlay} title={getVideoName(videoUrl)} key='video-player'>
          <video controls preload='metadata' width={660}>
            <source src={videoUrl} type='video/mp4' />
            Your browser does not support HTML video.
          </video>
        </DnDModal>
      </>
    );
  };
  const Audios = () => {
    const data = historyMessages.filter((item) => item.type === 'audio').reverse();
    return (
      <>
        <List
          className='max-h-96 overflow-y-auto'
          // itemLayout='horizontal'
          dataSource={data}
          loadMore={loadMore}
          loading={loading}
          renderItem={(item) => (
            <List.Item actions={[item.localDate]}>
              <List.Item.Meta
                avatar={
                  <Avatar size='small' style={CalColorStyle(item.senderName, true)}>
                    {item.senderName.substring(0, 5)}
                  </Avatar>
                }
              />
              <audio controls src={item.data.uri} className='h-6'></audio>
            </List.Item>
          )}
        />
      </>
    );
  };

  const FileList = () => {
    const data = historyMessages.filter((item) => item.type === 'file').reverse();
    const invokeSendUploadMessage = (item) => {
      const msgObjMerge = {
        sender: 'me',
        senderName: 'me',
        to: currentConversation.whatsapp_phone_number,
        date: new Date(),
        status: 'waiting',
        // ...msgObj,
        data: { link: item.data.uri, dataUri: item.data.uri, uri: item.data.uri, loading: 1 }, // ...fileObj.data,
        id: `${currentConversation.sn}.${uuid()}`,
        type: 'document',
        name: item.title,
      };
      const contentToRender = sentMsgTypeMapped[msgObjMerge.type].contentToRender(msgObjMerge);
      sentOrReceivedNewMessage(contentToRender.conversationid, contentToRender);

      const contentToSend = sentMsgTypeMapped[msgObjMerge.type].contentToSend(msgObjMerge);
      websocket.sendMessage({ ...contentToSend, opi_sn: userId, coli_sn: currentConversation.coli_sn, conversationid: currentConversation.sn });
      setOpenPopup(false);
    };
    return (
      <>
        {/* {data.length === 0 && <Empty />} */}
        <List
          className='max-h-96 overflow-y-auto'
          // itemLayout='horizontal'
          dataSource={data}
          loadMore={loadMore}
          loading={loading}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar size='small' style={CalColorStyle(item.sender, true)}>
                    {item.senderName}
                  </Avatar>
                }
                title={
                  <a href={item.data.uri} target='_blank' rel='noreferrer'>
                    {item.title}
                  </a>
                }
                description={
                  <Flex>
                    <div className='flex-auto'>{item.localDate}</div>
                    <Button key='copyv' onClick={() => handleCopyClick(item.data.uri)} type='link' size='small'>
                      复制🔗
                    </Button>
                    {/* <Button key={'resend'} onClick={() => invokeSendUploadMessage(item)} type='link' size='small'>
                      重发
                    </Button> */}
                  </Flex>
                }
              />
              {item.text}
            </List.Item>
          )}
        />
      </>
    );
  };

  const EmailList = () => {
    const data = historyMessages.filter((item) => item.type === 'email').reverse();

    const [openEmailDetail, setOpenEmailDetail] = useState(false);
    const [emailDetail, setEmailDetail] = useState({});
    const onOpenEmail = (email_detail) => {
      setOpenEmailDetail(true);
      setEmailDetail(email_detail);
    };

    return (
      <>
        {/* {data.length === 0 && <Empty />} */}
        <List
          className='max-h-96 overflow-y-auto'
          // itemLayout='horizontal'
          dataSource={data}
          loadMore={loadMore}
          loading={loading}
          renderItem={({ msgtext, ...item }) => (
            <List.Item
              // actions={[item.localDate]}
              className='cursor-pointer'
              onClick={() => {
                onOpenEmail({ msgtext, ...item });
                setOpenPopup(false);
              }}>
              <List.Item.Meta
                avatar={
                  item.sender === 'me' ? <SendPlaneFillIcon className='text-primary' /> : <InboxIcon className='text-indigo-500' />
                  // <Avatar size='small' style={CalColorStyle(item.senderName)}>
                  //    {item.senderName.substring(0, 3)}
                  //  </Avatar>
                }
                title={msgtext?.email?.subject}
                // description={`To: ${msgtext.to}`}
                description={
                  <Flex justify={'space-between'} className='max-w-full overflow-hidden'>
                    <div className='flex-auto line-clamp-1 break-all pr-2'>{`To: ${msgtext.to}`}</div>
                    <div className=' basis-32 flex-grow-0 flex-shrink-0'>{item.localDate}</div>
                  </Flex>
                }
              />
              {msgtext?.email?.abstract}
            </List.Item>
          )}
        />
        <EmailDetail open={openEmailDetail} setOpen={setOpenEmailDetail} emailMsg={emailDetail} key={`email-detail-1-${emailDetail.id}`} />
      </>
    );
  };

  return (
    <>
      <Popover
        // destroyTooltipOnHide
        placement='bottom'
        overlayClassName={[mobile === false ? 'w-2/5' : 'w-full max-h-full', 'max-h-[70%]'].join(' ')}
        // 'min-w-2/5 max-w-2/5 max-h-[70%]'
        trigger={'click'}
        open={openPopup}
        onOpenChange={setOpenPopup}
        content={
          <>
            <Tabs
              defaultActiveKey='email'
              size={'small'}
              className='bg-white *:m-0 '
              items={[
                // { key: 'all', label: '全部', children: <LongList /> },
                { key: 'image', label: '图片', children: <Album /> },
                { key: 'video', label: '视频', children: <Videos /> },
                { key: 'audio', label: '音频', children: <Audios /> },
                { key: 'file', label: '文件', children: <FileList /> },
                { key: 'email', label: '邮件', children: <EmailList /> },
              ]}
            />
          </>
        }>
        <Button icon={<FileSearchOutlined />} disabled={isEmpty(currentConversation.sn)} type='text' size='middle' title='消息记录' />
      </Popover>
    </>
  );
};
export default MessageListFilter;
