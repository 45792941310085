import { useEffect, useState } from 'react'
import { App, Button } from 'antd'
import EmailEditorPopup from '../Input/EmailEditorPopup'
import { useOrderStore } from '@/stores/OrderStore'
import useAuthStore from '@/stores/AuthStore'
import useConversationStore from '@/stores/ConversationStore'
import { getEmailQuotationDraftAction } from '@/actions/EmailActions'
import { isEmpty } from '@/utils/commons'

const EmailQuotation = ({ sfi_sn, ...props }) => {
  const {notification} = App.useApp()
  const currentConversation = useConversationStore((state) => state.currentConversation)
  const { userId, username, emailList, email } = useAuthStore((state) => state.loginUser)
  const [orderDetail, customerDetail] = useOrderStore((s) => [s.orderDetail, s.customerDetail])

  const emailListOption = emailList?.map((ele) => ({ ...ele, label: ele.email, key: ele.email, value: ele.email })) || []
  const emailListAddrMapped = emailListOption?.reduce((r, v) => ({ ...r, [v.email]: v }), {})

  const [pickEmail, setPickEmail] = useState({ key: email, email })

  useEffect(() => {
    const order_opi = Number(orderDetail?.opi_sn || userId)

    const find =
      emailListOption?.find((ele) => ele.opi_sn === order_opi && ele.default === true) ||
      emailListOption?.find((ele) => ele.opi_sn === order_opi && ele.backup === true) ||
      emailListOption?.find((ele) => ele.opi_sn === order_opi) ||
      emailListOption?.find((ele) => ele.default === true) ||
      emailListOption?.find((ele) => ele.backup === true) ||
      emailListOption[0]
    setPickEmail(find)
    return () => {}
  }, [orderDetail])

  const [draftLoading, setDraftLoading] = useState(false)
  const [draft, setDraft] = useState({})
  const getEmailDraft = async ({ sfi_sn, coli_sn, lgc = 1 }) => {
    if (isEmpty(sfi_sn)) {
      return false
    }
    try {
      setDraftLoading(true)
      const data = await getEmailQuotationDraftAction({ sfi_sn, coli_sn, lgc })
      setDraft(data)
      setDraftLoading(false)
    } catch (err) {
      setDraftLoading(false)
      notification.error({
        message: '请求失败',
        description: err.message || '网络异常',
        placement: 'top',
        duration: 3,
      })
    }
  }

  const [editorOpen, setEditorOpen] = useState(false)


  return (
    <>
      <Button loading={draftLoading}
        type='link'
        key={'email-now'} size='small'
        onClick={async () => {
          await getEmailDraft({ sfi_sn, coli_sn: currentConversation.coli_sn })
          setEditorOpen(true)
        }}>
        邮件
      </Button>

      <EmailEditorPopup
        open={editorOpen}
        setOpen={setEditorOpen}
        fromEmail={pickEmail.key}
        fromUser={orderDetail.opi_sn}
        toEmail={currentConversation?.channels?.email || customerDetail?.email}
        fromOrder={currentConversation.coli_sn}
        oid={orderDetail?.order_no}
        conversationid={currentConversation.sn}
        // emailMsg={ReferEmailMsg}
        // quoteid={mailID}
        // initial={{ ...initialPosition, ...initialSize }}
        // mailData={mailData}
        draft={draft}
        // customerDetail={customerDetail}
        action={'new'}
        key={`email-quotation-new-popup_${currentConversation.sn}`}
      />
    </>
  )
}
export default EmailQuotation
