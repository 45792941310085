import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { fetchJSON, postForm } from '@/utils/request'
import { API_HOST } from '@/config'
import { copy } from '@/utils/commons'

const useSnippetStore = create(devtools((set, get) => ({

  ownerList: [],
  typeList: [],
  typeAllList: [],
  snippetList: [],
  drawerOpen: false,
  currentSnippet: {
    snippetId: 0,
    title: '',
    owner: '',
    category: '',
    content: '',
  },

  openDrawer: () => {
    set(() => ({
      drawerOpen: true
    }))
  },

  closeDrawer: () => {
    set(() => ({
      drawerOpen: false
    }))
  },

  fetchParamList: async () => {
    const fetchOwnerUrl = `${API_HOST}/GetAutoDocParameters`
    const params = {};

    return fetchJSON(fetchOwnerUrl, params)
      .then(json => {
        if (json.errcode === 0) {
          const mapTypeList = json?.result?.type.map(item => {
            return { value: item.vsn, label: item.vname }
          })
          const mapTypeAllList = copy(mapTypeList);
          mapTypeAllList.unshift({ value: '', label: '全部' });
          set(() => ({
            ownerList: json?.result?.owner.map(item => {
              return { value: item.vsn, label: item.vname }
            }),
            typeList: mapTypeList,
            typeAllList: mapTypeAllList
          }))
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  fetchSnippetList: async (formValues) => {
    const fetchSnippetListUrl = `${API_HOST}/QueryAutoDocInfo`

    return fetchJSON(fetchSnippetListUrl, formValues)
      .then(json => {
        if (json.errcode === 0) {
          set(() => ({
            snippetList: json?.result
          }))
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  fetchSnippetDetail: async (snippet) => {
    const fetchSnippetDetailUrl = `${API_HOST}/GetAutoDocInfoDetail`
    const params = {adi_sn: snippet.adi_sn};

    return fetchJSON(fetchSnippetDetailUrl, params)
      .then(json => {
        if (json.errcode === 0 && json?.result.length > 0) {
          const detail = json?.result[0]
          const content = detail.adi_content
          set(() => ({
            currentSnippet: {
              snippetId: snippet.adi_sn,
              title: snippet.adi_title,
              owner: snippet.adi_owner,
              category: snippet.adi_type,
              content: detail.adi_content,
            }
          }))
          return content
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  createSnippet: (userId) => {
    return {
      snippetId: null,
      title: '',
      owner: userId + '/2',
      category: null,
      content: '',
      opi_sn: userId
    }
  },

  saveOrUpdateSnippet: async (formValues) => {
    const postSnippetUrl = `${API_HOST}/AddAutoDocInfo`
    const formData = new FormData()
    formData.append('adi_sn', formValues.snippetId)
    formData.append('owner', formValues.owner)
    formData.append('type', formValues.category)
    formData.append('title', formValues.title)
    formData.append('adi_content', formValues.content.htmlContent)
    formData.append('opi_sn', formValues.opi_sn)

    return postForm(postSnippetUrl, formData)
      .then(json => {
        if (json.errcode === 0) {
          set(() => ({
            currentSnippet: {
              snippetId: formValues.snippetId,
              title: formValues.title,
              owner: formValues.owner,
              category: formValues.category,
              content: formValues.content.htmlContent,
            }
          }))
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  deleteCurrentSnippet: async () => {
    const {currentSnippet} = get()
    const deleteSnippetUrl = `${API_HOST}/DeleteAutoDocInfo`
    const params = {adi_sn: currentSnippet.snippetId};

    return fetchJSON(deleteSnippetUrl, params)
      .then(json => {
        if (json.errcode === 0) {
          console.info(json)
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },
}), { name: 'snippetStore' }))

export default useSnippetStore
