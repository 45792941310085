// import './ReloadPrompt.css';
import { clearAllCaches } from '@/utils/commons';
import { useRegisterSW } from 'virtual:pwa-register/react';
// import { pwaInfo } from 'virtual:pwa-info';

import { BUILD_VERSION, BUILD_DATE } from '@/config'

// console.log(pwaInfo, 'pwaInfo');

function ReloadPrompt({ force }) {

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    // If onRegisteredSW is present, onRegistered will never be called.
    onRegistered(r) {
      r &&
      setTimeout(() => {
        console.log('Checking for sw update, onRegistered', BUILD_DATE, BUILD_VERSION);
        r.update();

        setInterval(() => {
          r.update();
        }, 1000 * 60 * 60 * 8);
      }, 1000 * 3);
    },
    onRegisteredSW(swUrl, r) {
      r &&
      setTimeout(() => {
        console.log('Checking for sw update, onRegisteredSW', BUILD_DATE, BUILD_VERSION);
        r.update();

        setInterval(() => {
          r.update();
        }, 1000 * 60 * 60 * 1);
      }, 1000 * 3);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  const forceReload = async () => {
    updateServiceWorker(true);
    await clearAllCaches();
    window.location.reload(true);
  }

  return (
    <>
      {/* {offlineReady && (<span>{APP_VERSION}</span>) } */}
      {(force || needRefresh) && (
        <a
          className=' text-sky-600 pr-2'
          onClick={() => {
            needRefresh ? updateServiceWorker(true) : forceReload()
          }}>
          系统更新{needRefresh && '🚀'}
        </a>
      )}
    </>

    // <div className='ReloadPrompt-container'>
    //   {(offlineReady || needRefresh) && (
    //     <div className='ReloadPrompt-toast'>
    //       <div className='ReloadPrompt-message'>{offlineReady ? <span>可安装</span> : <span>新版本发布了，点击重新加载按钮更新。</span>}</div>
    //       {needRefresh && (
    //         <button className='ReloadPrompt-toast-button' onClick={() => updateServiceWorker(true)}>
    //           重新加载
    //         </button>
    //       )}
    //       <button className='ReloadPrompt-toast-button' onClick={() => close()}>
    //         关闭
    //       </button>
    //     </div>
    //   )}
    //   <div className='ReloadPrompt-date'>__BUILD_DATE__ GMT</div>
    // </div>
  );
}

export default ReloadPrompt;
