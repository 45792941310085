import { useEffect } from 'react'
import { isEmpty } from '@/utils/commons'
import { App, notification } from 'antd'
import useConversationStore from '@/stores/ConversationStore'

export const useGlobalNotify = () => {
  // const { notification } = App.useApp() // 在AntApp 中，App.useApp() 获取不到notification

  const [globalNotify, clearGlobalNotify] = useConversationStore((state) => [state.globalNotify, state.clearGlobalNotify])

  useEffect(() => {
    if (isEmpty(globalNotify)) {
      return () => {}
    }
    // message.info(globalNotify[0].content, 3)
    notification.open({
      key: globalNotify[0].key,
      message: globalNotify[0].title,
      description: globalNotify[0].content,
      duration: 6,
      placement: 'top',
      type: globalNotify[0].type,
      onClick: () => {
        clearGlobalNotify()
      },
    })
    // setTimeout(() => {
    //   clearGlobalNotify()
    // }, 3030)

    return () => {}
  }, [globalNotify])

  return {};
}
