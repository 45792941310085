import { fetchJSON, postForm } from '@/utils/request';
import { API_HOST, EMAIL_HOST } from '@/config';

const parseHTMLString = (html) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const bodyContent = doc.body.innerHTML

  return bodyContent
}

/**
 * 获取顾问签名
 * @param {object} { opi_sn }
 */
export const getSalesSignatureAction = async (params) => {
  try {
    const { result } = await fetchJSON(`${EMAIL_HOST}/email_sign`, params)
    const { SignContent: html } = result
    return parseHTMLString(html);
  } catch (error) {
    return '';
  }
};

/**
 * 发送邮件
 */
export const postSendEmail = async (body) => {
  const { attaList=[], atta, content, ...bodyData } = body;
  const formData = new FormData();
  Object.keys(bodyData).forEach(function (key) {
    formData.append(key, bodyData[key]);
  });
  attaList.forEach(function (item) {
    formData.append('attachment', item);
  });
  const { result } = await postForm(`${EMAIL_HOST}/sendmail`, formData);
  return result;
};

/**
 * 重发邮件
 * @param {object} { mai_sn }
 */
export const postResendEmailAction = async (body) => {
  const { attaList, atta, content, ...bodyData } = body;
  const formData = new FormData();
  Object.keys(bodyData).forEach(function (key) {
    formData.append(key, bodyData[key]);
  });
  return await postForm(`${EMAIL_HOST}/email_resend`, formData);
};

const encodeEmailInfo = (info) => {
  const encodeQuote = (str = '') => str.replace(/"/g, ''); //.replace(/</g,'&lt;').replace(/>/g,'&gt;')

  return {
    ...info,
    MAI_From: encodeQuote(info.MAI_From),
    MAI_To: encodeQuote(info.MAI_To),
  }
};
/**
 * 邮件详情
 * @param {object} { mai_sn }
 */
export const getEmailDetailAction = async (params) => {
  const { result } = await fetchJSON(`${EMAIL_HOST}/getmail`, params);
  const mailType = result.MailInfo?.[0]?.MAI_ContentType || '';

  return { info: encodeEmailInfo(result.MailInfo?.[0] || {}), content: mailType === 'text/html' ? parseHTMLString((result.MailContent || '').replace(/\r\n/g, '')) : (result.MailContent || ''), attachments: result?.AttachList || [] };
}

export const getEmailOrderAction = async ({ colisn }) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/getorderinfo`, { colisn })
  return errcode === 0 ? { ...result[0], customerDetail: result[0].contact[0] } : {}
}


/**
 * 主动收邮件, 单个账户
 * @param {object} { opi_sn, }
 */
export const getEmailFetchAction = async (params) => {
  const { opi_sn, } = params
  const { result } = await fetchJSON(`${EMAIL_HOST}/email_fetch`, {
    opi_sn,
  })
  return result
};

/**
 * 报价信邮件草稿
 * @param {object} { sfi_sn, coli_sn, lgc }
 */
export const getEmailQuotationDraftAction = async (params) => {
  const { result } = await fetchJSON(`${EMAIL_HOST}/QuotationLetter`, params)
  return { subject: (result.Subject || ''), content: parseHTMLString((result.MailContent || '').replace(/\r\n/g, '')) }
}

/**
 * 单个邮件绑定订单
 * @param {object} { conversationid, mai_sn, coli_sn, coli_id, sourcetype }
 */
export const fetchEmailBindOrderAction = async (params) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/mailinfo_bindorder`, params)
  return errcode === 0 ? true : false;
}
