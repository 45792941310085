import { createContext, useEffect, useState } from 'react'
import { useOrderStore } from '@/stores/OrderStore'
import { Tooltip, Button } from 'antd'

const PaymentlinkBtn = ({ ...props }) => {
  const [openPaymentDrawer] = useOrderStore((state) => [state.openDrawer])

  return (
    <Tooltip title='支付链接' >
      {/* <Button type='text' onClick={() => openPaymentDrawer()} icon={<DollarOutlined className='text-orange-500' />} size={'middle'} /> */}
      <Button type='text' onClick={() => openPaymentDrawer()}  size={'middle'} className='px-1' >💲</Button>
    </Tooltip>
  )
}
export default PaymentlinkBtn
