import { loadScript, readWebsocketLog } from '@/utils/commons'
import { BUILD_VERSION, BUILD_DATE } from '@/config'

export const loadPageSpy = (title) => {
  if (import.meta.env.DEV || window.$pageSpy) return

  const PageSpyConfig = { api: 'page-spy.mycht.cn', project: 'Sales CRM', title: title + '(v' + BUILD_VERSION + ')', autoRender: false, offline: true }

  const PageSpySrc = [
    'https://page-spy.mycht.cn/page-spy/index.min.js' + `?${BUILD_DATE}`,
    'https://page-spy.mycht.cn/plugin/data-harbor/index.min.js' + `?${BUILD_DATE}`,
    'https://page-spy.mycht.cn/plugin/rrweb/index.min.js' + `?${BUILD_DATE}`,
  ]
  Promise.all(PageSpySrc.map((src) => loadScript(src))).then(() => {
    // 注册插件
    window.$harbor = new DataHarborPlugin()
    window.$rrweb = new RRWebPlugin()
    ;[window.$harbor, window.$rrweb].forEach((p) => {
      PageSpy.registerPlugin(p)
    })
    window.$pageSpy = new PageSpy(PageSpyConfig)

    // PageSpy.registerPlugin(new DataHarborPlugin());
    // PageSpy.registerPlugin(new RRWebPlugin());
    // 实例化 PageSpy
    // window.$pageSpy = new PageSpy({ api: 'page-spy.mycht.cn', project: 'Sales CRM', title: title + '(v' + BUILD_VERSION + ')', autoRender: false, offline: true,  });
    console.log('[PageSpy]', window.$pageSpy.version)
    // window.addEventListener('beforeunload', (e) => {
    //   e.preventDefault() // If you prevent default behavior in Mozilla Firefox
    //   e.returnValue = '' // Chrome requires returnValue to be set

    //   window.$harbor.upload({ clearCache: false, remark: '自动上传' }) // 上传日志 { clearCache: true, remark: '' }
    // })
    window.onerror = async function (msg, url, lineNo, columnNo, error) {
      await readWebsocketLog()
      // 上传最近 3 分钟的日志
      const now = Date.now()
      await window.$harbor.uploadPeriods({
        startTime: now - 3 * 60000,
        endTime: now,
        remark: `\`onerror\`自动上传. ${msg}`,
      })
    }
  })
}

export const uploadPageSpyLog = async () => {
  await readWebsocketLog()
  // window.$pageSpy.triggerPlugins('onOfflineLog', 'upload');
  if (window.$pageSpy) {
    await window.$harbor.upload() // 上传日志 { clearCache: true, remark: '' }
  }
}
