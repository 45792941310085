import React, {  } from 'react';
import { WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import { WABIcon,  } from '@/components/Icons';

const ChannelLogo = ({channel, className, ...props}) => {
  // if is array, get last
  if (Array.isArray(channel)) {
    channel = channel[channel.length - 1];
  }
  const _channel = (channel || '').toLowerCase();
  switch (_channel) {
    case 'waba':
      return <WABIcon key={channel} className={`text-whatsapp ${className}  `} />;
    case 'wa':
    case 'wai':
    case 'whatsapp':
      return <WhatsAppOutlined key={channel} className={`text-whatsapp ${className}  `} />;
    case 'email':
      return <MailOutlined key={channel} className={`text-indigo-500 ${className} `} />
    default:
      // return <MailOutlined key={'channel'} className={`text-indigo-500 ${className} `} />
      return <WABIcon key={channel} className={`text-whatsapp ${className}  `} />;
  }
}
export default ChannelLogo;
