import { useEffect, useRef, useState, forwardRef, memo } from 'react';
import { MessageBox } from 'react-chat-elements';
import { App, Button } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { useShallow } from 'zustand/react/shallow';
import useConversationStore from '@/stores/ConversationStore';
import { groupBy, isEmpty, } from '@/utils/commons';
import BubbleEmail from './Components/BubbleEmail';
import BubbleIM from './Components/BubbleIM';

const MessagesList = ({ messages, handlePreview, reference, longListLoading, getMoreMessages, shouldScrollBottom, loadNextPage, handleContactClick, setNewChatModalVisible, setNewChatFormValues, ...listProps }) => {

  // const messagesEndRef = useRef(null);
  const messageRefs = useRef([]);
  const [focusMsg, setFocusMsg] = useState('');
  useEffect(() => {
    setTimeout(() => {
      setFocusMsg('');
    }, 3500);

    return () => '';
  }, [focusMsg])

  const [previousScrollHeight, setPreviousScrollHeight] = useState(0);
  const scrollToBottom = (force = false) => {
    if (reference.current && (shouldScrollBottom || force)) {
      reference.current.scrollTop = reference.current.scrollHeight;
    } else if (shouldScrollBottom === false) {
      requestAnimationFrame(() => {
        if (reference.current) {
          reference.current.scrollTop = reference.current.scrollHeight - previousScrollHeight
        }
      })
    }
  };

  const scrollToMessage = (id, index) => {
    const _i = index || messages.findIndex((msg) => msg.id === id);
    if (_i >= 0) {
      messageRefs.current[_i].scrollIntoView({ behavior: 'smooth', block: 'start' });
      setFocusMsg(id);
    }
  };

  useEffect(scrollToBottom, [messages]);

  const onLoadMore = async () => {
    setPreviousScrollHeight(reference.current?.scrollHeight || 0);
    await getMoreMessages();
  };
  // eslint-disable-next-line react/display-name
  const MessageBoxWithRef = forwardRef((props, ref) => (
    <div ref={ref}>
      {['waba', 'wa', 'wai', 'whatsapp'].includes((props.msg_source || '').toLowerCase())
        && <BubbleIM {...props} {...{ scrollToMessage, focusMsg, handleContactClick, setNewChatModalVisible, setNewChatFormValues, handlePreview }} />}
      {props.msg_source === 'email' && <BubbleEmail {...props} onOpenEditor={listProps.onOpenEditor} onOpenEmail={listProps.onOpenEmail} />}
    </div>
  ));

  return (
    <div className='relative h-full overflow-x-hidden flex flex-1'>
      <div ref={reference} className='relative overflow-y-auto overflow-x-hidden block flex-1' key={'msg-list'}>
        {loadNextPage && messages.length > 0 && (
          <div className='text-center pt-3 mb-3 h-8 leading-8 '>
            {!longListLoading ? (
              <Button onClick={onLoadMore} type={'dashed'}>
                loading more
              </Button>
            ) : (
              <LoadingOutlined className='text-primary' />
            )}
          </div>
        )}
        {messages.map((message, index) => (
          <MessageBoxWithRef
            ref={(el) => (messageRefs.current[index] = el)}
            key={`${message.type || ''}.${message.id}`}
            {...message}
          />
        ))}
      </div>
      <Button onClick={() => scrollToBottom(true)} ghost type={'dashed'} shape={'circle'} className=' absolute bottom-1 right-4' icon={<DownOutlined />} />
    </div>
  );
};

export default MessagesList;
