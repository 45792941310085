import React, { useState, useEffect } from 'react';
import { Button, Tag, Radio, Popover, Form } from 'antd';
import { isEmpty, objectMapper, TagColorStyle } from '@/utils/commons';
import useConversationStore from '@/stores/ConversationStore';
import { OrderLabelDefaultOptions } from '@/stores/OrderStore';
import { FilterIcon } from '@/components/Icons';

const otypes = [
  { label: 'All', value: '', labelValue: '' },
  ...OrderLabelDefaultOptions.filter((o) =>
    [240003, 240002].includes(o.value),
  ).map((o) => ({ ...o, labelValue: o.value, value: `label@${o.value}` })),
  { value: `state@5`, labelValue: 5, label: '成行',  },
  { value: `intour@1`, labelValue: 1, label: '走团中',  },
]
const otypesMapped = otypes.reduce((acc, cur) => ({ ...acc, [cur.value]: cur }), {});
const ChatListFilter = ({ onFilterChange, activeList, ...props }) => {
  const [
    { tags: selectedTags, otype: selectedOType, search, ...filter },
    setFilterTags, setFilterOtype, resetFilter
  ] = useConversationStore((state) => [
    state.filter,
    state.setFilterTags, state.setFilterOtype, state.resetFilter
  ]);

  const conversationsList = useConversationStore((state) => state.conversationsList);
  const closedConversationsList = useConversationStore((state) => state.closedConversationsList);

  /**
   * 前端搜索, 返回结果到列表
   */
  const handleFilter = async () => {
    const fromSource = activeList ? conversationsList : closedConversationsList;

    // input 搜索框
    let filterSearchFun = () => true;
    if (search.toLowerCase().trim() !== '') {
      const _search = search.toLowerCase().trim();
      filterSearchFun = (item) =>
        (item.conversation_memo || '').toLowerCase().includes(_search) ||
        (item.whatsapp_name || '').toLowerCase().includes(_search) ||
        (item.whatsapp_phone_number || '').toLowerCase().includes(_search) ||
        (item?.channels?.email || '').toLowerCase().includes(_search) ||
        (item.coli_id || '').toLowerCase().includes(_search);
    }


    // 订单筛选: 标签, 状态, 团内
    const [otypeC, v] = selectedOType ? selectedOType.split('@') : [];
    const otypeV = v ? parseInt(v) : '';
    let filterOTypeFun = () => true;
    switch (otypeC) {
      case 'label':
        filterOTypeFun = (item) => item.order_label_id === otypeV
        break
      case 'state':
        filterOTypeFun = (item) => item.order_state_id === otypeV
        break
      case 'intour':
        filterOTypeFun = (item) => item.intour === otypeV
        break

      default:
        break
    }
    // 会话自定义标签
    const filterTagFun = (item) => {
      const itemTagKeys = (item.tags || []).map(t => t.key);
      return isEmpty(selectedTags) ? true : (selectedTags || []).some(tele => (itemTagKeys).includes(tele));
    }

    // const filterWithParamRes = fromSource.filter(filterSearchFun).filter(filterOTypeFun).filter(filterTagFun);
    if (typeof onFilterChange === 'function') {
      onFilterChange();
    }
  };

  const [tags] = useConversationStore((state) => [state.tags]);
  const [form] = Form.useForm();
  const handleTagsChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...selectedTags, tag.key] : selectedTags.filter((t) => t !== tag.key);
    setFilterTags(nextSelectedTags);
    form.setFieldValue('tags', nextSelectedTags);
  };

  /**
   * @ignore
   */
  const onFinish = async (values) => {
    // const filterParam = objectMapper(values, { tags: {key:'tags', transform: (v) => v ? v.join(',') : ''} });
    const filterParam = objectMapper(values, { tags: {key:'tags', } });
    filterParam.otype = selectedOType;
    console.log('Received values of form[filter_form]: ', values, ' \n', filterParam);
    // await handleFilter(filterParam);
    setOpenPopup(false);
  };
  const onReset = () => {
    setFilterOtype('');
    resetFilter();
    form.resetFields();
    // if (typeof onFilterChange === 'function') {
    //   onFilterChange();
    // }
  }

  useEffect(() => {
    // handleFilter()
    if (typeof onFilterChange === 'function') {
      onFilterChange()
    }

    return () => {}
  }, [selectedTags, selectedOType, search ])

  const [openPopup, setOpenPopup] = useState(false);
  return (
    <>
      <div className='my-1 flex justify-between items-center '>
        <Radio.Group
          optionType={'button'}
          buttonStyle='solid'
          size='small'
          options={otypes}
          value={selectedOType}
          onChange={(e) => {
            setFilterOtype(e.target.value)
          }}
        />
        <Popover
          destroyTooltipOnHide
          placement='bottomLeft'
          overlayClassName='max-w-80'
          trigger={'click'}
          open={openPopup}
          onOpenChange={setOpenPopup}
          title={
            <div className='flex justify-between '>
              <div>更多会话筛选</div>
              <Button size='small' onClick={() => setOpenPopup(false)}>
                &times;
              </Button>
            </div>
          }
          content={
            <>
              <Form
                form={form}
                name='conversation_filter_form'
                layout='vertical'
                size='small'
                initialValues={{}}
                onFinish={onFinish}
                className='*:mb-2'>
                <Form.Item label='订单'>
                  <Tag
                    key={selectedOType}
                    closeIcon={selectedOType !== ''}
                    onClose={() => setFilterOtype('')}>
                    {otypesMapped[selectedOType]?.label || 'All'}
                  </Tag>
                </Form.Item>
                <Form.Item name={'tags'} label='标签' className='*.div:gap-1'>
                  {tags.map((tag, ti) => (
                    <Tag.CheckableTag
                      className='mb-1'
                      key={tag.key}
                      checked={selectedTags.includes(tag.key)}
                      onChange={(checked) => handleTagsChange(tag, checked)}
                      style={TagColorStyle(
                        tag.label,
                        selectedTags.includes(tag.key),
                      )}>
                      {tag.label}
                    </Tag.CheckableTag>
                  ))}
                </Form.Item>

                <Form.Item noStyle className='flex justify-center mb-0'>
                  <Button.Group>
                    <Button onClick={onReset} type='primary' ghost>
                      重置
                    </Button>
                    {/* <Button htmlType='submit' type='primary'>
                      确定
                    </Button> */}
                  </Button.Group>
                </Form.Item>
              </Form>
            </>
          }>
          <Button
            icon={
              <FilterIcon
                className={
                  isEmpty(selectedTags) ? 'text-neutral-500' : 'text-blue-500'
                }
              />
            }
            type='text'
            size='middle'
          />
        </Popover>
      </div>
    </>
  )
};
export default ChatListFilter;
