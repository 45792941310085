import useAuthStore from '@/stores/AuthStore'
import { Flex, Input, Button, Typography } from 'antd'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const { Title } = Typography

// 钉钉扫码开发文档：https://open.dingtalk.com/document/orgapp/tutorial-obtaining-user-personal-information#title-qpi-0qv-anm

function QRCode() {
  const navigate = useNavigate()

  const [result, setResult] = useState('')

  // const loginStatus = useAuthStore((state) => state.loginStatus)
  // const setLoginStatus = useAuthStore((state) => state.setLoginStatus)
  // const loginUser = useAuthStore((state) => state.loginUser)
  const login = useAuthStore((state) => state.login)
  const codeRef = useRef()

  useEffect(() => {
    // if (loginUser.userId > 0) {
    //   navigate('/')
    // }
  }, [])

  useEffect(() => {
    import(
      'https://g.alicdn.com/dingding/h5-dingtalk-login/0.21.0/ddlogin.js'
    ).then(() => {
      window.DTFrameLogin(
        {
          id: 'qrCodeContainer',
          width: 300,
          height: 300,
        },
        {
          redirect_uri: encodeURIComponent(
            'https://sales.mycht.cn/p/dingding/callback',
          ),
          client_id: 'dingwgdx6emlxr3fcrg8',
          scope: 'openid',
          response_type: 'code',
          state: 'global-sales',
          prompt: 'consent',
        },
        (loginResult) => {
          const { authCode } = loginResult
          setResult(authCode)
        },
        (errorMsg) => {
          // setLoginStatus(403)
          console.error(`Login Error: ${errorMsg}`)
        },
      )
    })
  }, [])

  return (
    <Flex justify='center' align='center' gap='middle' vertical>
      <Title level={4}>使用钉钉扫码</Title>
      <div
        id='qrCodeContainer'
        style={{
          border: '12px solid rgba(5, 5, 5, 0.06)',
          borderRadius: '8px',
        }}></div>
      <span>钉钉 authCode: {result}</span>
      <Input placeholder='placeholder' ref={codeRef} />
      <Button
        danger
        onClick={() => {
          console.info(codeRef.current.nativeElement.value)
          login(codeRef.current.nativeElement.value)
        }}>
        登录
      </Button>
    </Flex>
  )
}

export default QRCode
