import { useState, useEffect } from 'react';
import { Form, Input, Modal } from 'antd';
import { isEmpty, isNotEmpty, pick } from '@/utils/commons';
import useConversationStore from '@/stores/ConversationStore';
import { phoneNumberToWAID } from '@/channel/bubbleMsgUtils';
import { useConversationNewItem } from '@/hooks/useConversation';
import useAuthStore from '@/stores/AuthStore';

export const ConversationItemForm = ({ initialValues, onFormInstanceReady }) => {
  const [currentConversation] = useConversationStore((state) => [state.currentConversation]);
  const fromCurrent = initialValues?.is_current_order ? pick(currentConversation, ['coli_sn', 'coli_id', 'opi_sn']) : {};
  const [form] = Form.useForm();
  const [formatPhone, setFormatPhone] = useState('');
  const [formatVisible, setFormatVisible] = useState(false);

  const [contactValidateStatus, setContactValidateStatus] = useState('success');

  useEffect(() => {
    onFormInstanceReady(form);
    setFormatPhone(phoneNumberToWAID(initialValues.phone_number || ''));
  }, []);

  useEffect(() => {
    if (isEmpty(initialValues.wa_id)) form.setFieldValue('wa_id', formatPhone);
    return () => {};
  }, [formatPhone]);

  const onValuesChange = (changeValues, allValues) => {
    if ('phone_number' in changeValues) {
      const _t = phoneNumberToWAID(changeValues.phone_number);
      setFormatPhone(_t);
      setFormatVisible(_t !== changeValues.phone_number);
    }
    setContactValidateStatus((isEmpty(allValues.email) && isEmpty(allValues.phone_number) )? 'error' : 'success');
  };
  return (
    <Form
      layout='horizontal'
      form={form}
      name='form_in_modal'
      initialValues={{ ...fromCurrent, ...initialValues, }}
      onValuesChange={onValuesChange}
      labelCol={{ span: 4 }}
      labelWrap={true}>
      <Form.Item name={'name'} label='名称' rules={[{ required: true, message: '请输入联系人名称' }]}>
        <Input placeholder='请输入联系人名称' />
      </Form.Item>
      <Form.Item name={'remark'} label='备注' rules={[{ type: 'string', max: 500, message: '备注最多500字' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name={'phone_number'}
        label='手机'
        validateStatus={contactValidateStatus === 'success' ? (formatVisible ? 'warning' : undefined) : contactValidateStatus}
        help={formatVisible ? `WhatsApp格式: ${formatPhone}` : undefined}
        rules={[{ required: false, message: '请输入联系人手机号' }]}>
        <Input placeholder='请输入联系人手机号' disabled={!initialValues.is_new && isNotEmpty(initialValues.phone_number)} />
      </Form.Item>
      <Form.Item label='WhatsApp' name={'wa_id'} dependencies={['phone_number']}>
        <Input disabled={!initialValues.is_new && isNotEmpty(initialValues.wa_id)} />
      </Form.Item>
      <Form.Item
        name={'email'}
        label='邮箱'
        validateStatus={contactValidateStatus}
        help={contactValidateStatus === 'success' ? '' : '手机号或邮箱至少输入一个, 否则无法发送消息'}
        rules={[{ type: 'email', message: '请输入正确的邮箱地址' }]}>
        <Input disabled={!initialValues.is_new && isNotEmpty(initialValues.email)} />
      </Form.Item>
      {initialValues.is_current_order && (
        <>
          <Form.Item name={'coli_id'} label={'关联当前订单'} rules={[{ required: true, message: '关联的订单' }]} validateStatus='warning' help='请务必确认关联的订单是否正确'>
            <Input placeholder='请先关联订单' disabled={initialValues.is_current_order} />
          </Form.Item>
          <Form.Item name={'coli_sn'} label='订单SN' hidden={initialValues.is_current_order} rules={[{ required: true, message: '订单SN' }]}>
            <Input placeholder='订单SN' />
          </Form.Item>
        </>
      )}
      {/* <div className=' text-neutral-500 italic'>如果会话已存在, 将直接切换</div> */}
    </Form>
  )
};
/**
 * 新建会话弹窗
 * * 订单信息下的WhatsApp号码处点击: 关联此订单
 * * 消息记录中的号码点击: 不自动关联
 * * 消息记录中的联系人卡片点击: 不自动关联
 */
export const ConversationItemFormModal = ({ open, onCreate, onCancel, initialValues: _initialValues, }) => {
  const [formInstance, setFormInstance] = useState();
  const [newItemLoading, setNewItemLoading] = useState(false);
  const userId = useAuthStore((state) => state.loginUser.userId);

  const { newConversation } = useConversationNewItem();

  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    setInitialValues({
      ..._initialValues,
      phone_number: _initialValues?.whatsapp_phone_number || _initialValues?.phone_number || '',
      wa_id: _initialValues?.whatsapp_phone_number || _initialValues?.wa_id || '',
      name: _initialValues?.whatsapp_name || _initialValues?.name || '',
      remark: _initialValues?.conversation_memo || '',
      email: _initialValues?.email || _initialValues?.channels?.email || '',
      is_new: _initialValues?.is_new ?? true,
    });

    return () => {};
  }, [_initialValues])


  return (
    <Modal
      open={open} style={{ top: 20 }}
      title={initialValues.is_new ? '新建会话' : '编辑会话'}
      okText='确认'
      // cancelText='Cancel'
      okButtonProps={{
        autoFocus: true,
      }}
      confirmLoading={newItemLoading}
      onCancel={() => {
        onCancel();
        formInstance?.resetFields();
      }}
      destroyOnClose
      onOk={async () => {
        try {
          const values = await formInstance?.validateFields();
          // formInstance?.resetFields();

          setNewItemLoading(true);
          // if (initialValues.is_current_order !== true)
            await newConversation({...values, conversationid: _initialValues?.sn || '', opi_sn: userId});
          if (typeof onCreate === 'function') {
            onCreate(values);
          }
          setNewItemLoading(false);
        } catch (error) {
          console.log('Failed:', error);
        }
      }}>
      <ConversationItemForm
        initialValues={initialValues}
        onFormInstanceReady={(instance) => {
          setFormInstance(instance);
        }}
      />
    </Modal>
  );
};
export default ConversationItemFormModal;
