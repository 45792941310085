import { create } from "zustand";
import { VonageClient } from "@vonage/client-sdk";
import { fetchJSON } from "@/utils/request";
import { prepareUrl, isNotEmpty } from "@/utils/commons";
import { VONAGE_URL, DATETIME_FORMAT } from "@/config";
import dayjs from "dayjs";

const callCenterStore = create((set, get) => ({
	client: new VonageClient({ apiUrl: "https://api-ap-3.vonage.com", websocketUrl: "wss://ws-ap-3.vonage.com" }),
	call_id: 0,
	loading: false,
	logs: "",

	//初始化 Vonage
	init_vonage: user_id => {
		const { client, log } = get();
		set({ loading: true });
		const fetchUrl = prepareUrl(VONAGE_URL + "/jwt")
			.append("user_id", user_id)
			.build();
		return fetchJSON(fetchUrl).then(json => {
			if (json.status === 200) {
				let jwt = json.token;

				client
					.createSession(jwt)
					.then(sessionId => {
						log("Id of created session: ", sessionId);
					})
					.catch(error => {
						log("Error creating session: ", error);
					});

				client.on("sessionError", reason => {
					// After creating a session
					log("Session error reason: ", reason);
				});

				client.on("legStatusUpdate", (callId, legId, status) => {
					// After creating a session
					log({ callId, legId, status });
				});

				client.on("callInvite", (callId, from, channelType) => {
					log({ callId, from, channelType }); // Answer / Reject Call
				});

				client.on("callHangup", (callId, callQuality, reason) => {
					log(`Call ${callId} has hung up, callQuality:${callQuality}, reason:${reason}`);
					set({ call_id: 0 });
				});

				client.on("sessionError", error => {
					log({ error });
				});
			} else {
				throw new Error("请求jwt失败");
			}
			set({ loading: false });
		});
	},

	log: (...message) => {
		const { logs } = get();
		console.log(message);
		set({ logs: [...logs, dayjs().format(DATETIME_FORMAT) + " : " + JSON.stringify(message)] });
	},

	// 创建一个语音通话
	make_call: phone_number => {
		const { client, log } = get();
		if (!isNotEmpty(phone_number)) {
			log("请输入电话号码");
			return;
		}
		log("开始拨号:" + phone_number);
		if (client) {
			set({ loading: true });
			client
				.serverCall({ to: phone_number })
				.then(callId => {
					log("Id of created call: ", callId);
					set({ call_id: callId });
					set({ loading: false });
				})
				.catch(error => {
					log("Error making call: ", error);
					set({ loading: false });
				});
		}
	},

	// 挂断语音通话
	hang_up: () => {
		const { client, call_id, log } = get();
		log("挂断电话");
		if (call_id) {
			client.hangup(call_id);
			set({ call_id: 0 });
		}
	},
}));

export default callCenterStore;
