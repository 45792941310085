/* eslint-disable react/display-name */
import { memo } from 'react';
import { Form, Flex, Input, Button, DatePicker, Select } from 'antd';
import SearchInput from '@/components/SearchInput';
import { isNotEmpty } from '@/utils/commons';
import { fetchSalesAgentWithDD as fetchSalesAgent, fetchCustomerList } from '@/actions/CommonActions';

const { RangePicker } = DatePicker;
const SearchForm = memo(function ({ initialValues, onSubmit, onReset }) {
  const [form] = Form.useForm();
  function handleSubmit(values) {
    const multiAgents = (values?.agent || []).map((ele) => ele.value).join(',');
    const multiCustomers = (values?.customer || []).map((ele) => ele.value).join(',');
    onSubmit?.({
      ...values,
      opisn: multiAgents,
      whatsapp_id: multiCustomers,
      ...(isNotEmpty(values.msgDateRange)
        ? {
            from_date: values.msgDateRange[0].format('YYYY-MM-DD'),
            end_date: values.msgDateRange[1].format('YYYY-MM-DD'),
          }
        : {}),
    });
  }
  return (
    <Form layout={'inline'} form={form} initialValues={initialValues} onFinish={handleSubmit} style={{}}>
      <Flex className='w-full'>
        <Flex flex={'auto'} wrap='wrap' gap={4}>
          <Form.Item label='发送人' name='agent' style={{ width: '200px' }} rules={[{ required: false, message: '请选择发送人' }]}>
            <SearchInput placeholder='搜索发送人' fetchOptions={fetchSalesAgent} mode={'multiple'} maxTagCount={0} />
          </Form.Item>
          <Form.Item label='客人' name='customer' style={{ width: '200px' }}>
            <SearchInput placeholder='搜索客人' fetchOptions={fetchCustomerList} mode={'tags'} maxTagCount={0} />
          </Form.Item>
          <Form.Item label='订单号' name='coli_id'>
            <Input placeholder='订单号' allowClear />
          </Form.Item>
          <Form.Item label='关键词' name='search'>
            <Input placeholder='关键词' allowClear />
          </Form.Item>
          <Form.Item label='消息渠道' name='mchannel' className='w-52'>
            <Select mode='multiple' maxTagCount={0} allowClear options={[
                { key: 'waba', label: 'WA商业号', value: 'waba' },
                { key: 'email', label: '邮件', value: 'email' },
                { key: 'whatsapp', label: 'WhatsApp', value: 'whatsapp' },
                ]} className='w-8' />
          </Form.Item>
          <Form.Item label='消息类型' name='mtype' className='w-44'>
            <Select mode='multiple' maxTagCount={0} allowClear options={[
                { key: 'image', label: '图片', value: 'image' },
                { key: 'video', label: '视频', value: 'video' },
                { key: 'audio', label: '音频', value: 'audio' },
                { key: 'file', label: '文件', value: 'file' },
                { key: 'email', label: '邮件', value: 'email' },
                ]} className='w-8' />
          </Form.Item>
          <Form.Item label='日期' name='msgDateRange'>
            <RangePicker format={'YYYY-MM-DD'} />
          </Form.Item>
        </Flex>
        <div style={{ flex: '0 1 64px' }} className='flex justify-between'>
          {/* <Button
            onClick={() => {
              form.resetFields();
              if (typeof onReset === 'function') {
                onReset();
              }
            }}>
            重置
          </Button> */}
          <Button type='primary' htmlType='submit'>
            搜索
          </Button>
        </div>
      </Flex>
    </Form>
  );
});
export default SearchForm;
