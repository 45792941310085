import { useCallback, useState, useEffect } from "react";
import { Grid, Divider, Layout, Flex, Spin, Input, Col, Row, List, Typography } from "antd";
import { PhoneOutlined, CustomerServiceOutlined, AudioOutlined } from "@ant-design/icons";
import { useParams, useHref, useNavigate } from "react-router-dom";
import { isEmpty } from "@/utils/commons";

import callCenterStore from "@/stores/CallCenterStore";
import useAuthStore from "@/stores/AuthStore";

const CallCenter = props => {
	const href = useHref();
	const navigate = useNavigate();
	const { phonenumber } = useParams();
	const [init_vonage, make_call, hang_up, logs, call_id, loading] = callCenterStore(state => [state.init_vonage, state.make_call, state.hang_up, state.logs, state.call_id, state.loading]);
	const [loginUser] = useAuthStore(state => [state.loginUser]);
	const [phone_number, setPhone_number] = useState(phonenumber);

	useEffect(() => {
		if (loginUser.userId === -1 && href.indexOf("/p/") === -1) {
			navigate("/p/dingding/login?origin_url=" + href);
		} else {
			init_vonage(loginUser.userId);
		}
	}, [href, navigate, init_vonage, loginUser]);

	const oncall = () => {
		if (isEmpty(call_id)) {
			make_call(phone_number);
		} else {
			hang_up();
		}
	};

	return (
		<>
			<br />
			<Row gutter={16}>
				<Col md={24} lg={8} xxl={9}></Col>
				<Col md={24} lg={8} xxl={6}>
					<Input.Search
						type="tel"
						size="large"
						defaultValue={phone_number}
						placeholder="电话号码"
						prefix={<AudioOutlined />}
						suffix={loading ? <Spin /> : ""}
						enterButton={call_id ? "挂断" : "拨号"}
						onSearch={oncall}
						onChange={e => {
							setPhone_number(e.target.value);
						}}></Input.Search>
				</Col>
				<Col md={24} lg={8} xxl={9}></Col>
			</Row>

			<Divider plain orientation="left" className="mb-0"></Divider>
			<List header={<Typography.Text strong>Console Logs</Typography.Text>} bordered dataSource={logs} renderItem={item => <List.Item>{item}</List.Item>} />
		</>
	);
};
export default CallCenter;
