import useAuthStore from '@/stores/AuthStore';
import useConversationStore from '@/stores/ConversationStore';
import { fetchConversationsList, fetchOrderConversationsList, postNewOrEditConversationItem } from '@/actions/ConversationActions';
import { isEmpty } from '@/utils/commons';

const CHAT_ITEM_RECORD = {
  "sn": null,
  "opi_sn": 0,
  "coli_sn": null,
  "whatsapp_phone_number": null,
  "last_received_time": null,
  "last_send_time": null,
  "unread_msg_count": 0,
  "whatsapp_name": null,
  "coli_id": "",
  "conversation_expiretime": null,
  "tags": null,
  "channels": {
      "email": null,
      "phone_number": null,
      "whatsapp_phone_number": null
  },
  "nick_name": null,
  "top_state": 0,
  "conversation_memo": null,
  "order_label_id": null,
  "order_state_id": null,
  "intour": 0,
  "last_message": {},
  "lasttime": ""
};

export function useConversationNewItem() {
  const [currentConversation, setCurrentConversation, updateConversationItem] = useConversationStore((state) => [
    state.currentConversation,
    state.setCurrentConversation,
    state.updateConversationItem,
  ]);
  const conversationsList = useConversationStore((state) => state.conversationsList);
  const addToConversationList = useConversationStore((state) => state.addToConversationList);
  const userId = useAuthStore((state) => state.loginUser.userId);
  /**
   * 打开订单的会话, 不存在自动新增
   */
  const openOrderContactConversation = async (whatsappID) => {
    const { coli_sn, opi_sn } = currentConversation;
    let findCurrentOrderChats = conversationsList.filter(
      (item) => `${item.coli_sn}` === `${coli_sn}` && `${item.opi_sn}` === `${opi_sn}` && `${item.whatsapp_phone_number}` === `${whatsappID}`
    );
    let findCurrentIndex = isEmpty(findCurrentOrderChats) ? -1 : 0; // findCurrentOrderChats.length-1;
    let findCurrent = findCurrentOrderChats[findCurrentIndex];
    if (findCurrentIndex !== -1) {
      addToConversationList(findCurrentOrderChats);
    } else if (!isEmpty(whatsappID)) {
      const data = await fetchOrderConversationsList({ opisn: opi_sn, colisn: coli_sn, whatsappid: whatsappID });
      if (!isEmpty(data)) {
        addToConversationList(data);
        findCurrentIndex = data.findIndex((item) => `${item.coli_sn}` === `${coli_sn}` && `${item.opi_sn}` === `${opi_sn}` && `${item.whatsapp_phone_number}` === `${whatsappID}`);
        findCurrentIndex = findCurrentIndex >= 0 ? findCurrentIndex : 0;
        findCurrent = data[findCurrentIndex];
      }
    }
    if (findCurrentIndex >= 0) {
      setCurrentConversation(findCurrent);
    }
    return false;
  };

  /**
   * 创建新会话/编辑会话
   */
  const newConversation = async (body) => {
    const { wa_id, remark, opi_sn: opisn, conversationid } = body;
    const newChat = {
      whatsapp_phone_number: wa_id,
      remark: remark || '',
      guest_email: body.email || '',
      guest_phone: body.phone_number || '',
      guest_name: body.name || '',
    }
    let buildChatItem = {};
    const createdNew = await postNewOrEditConversationItem({ ...newChat, opisn: opisn || userId, conversationid });
    // addToConversationList([{...createdNew, sn: createdNew.conversationid}]);
    // const _list = await fetchConversationsList({ opisn });
    // addToConversationList(_list, 'top');
    if (!isEmpty(createdNew)) {
      buildChatItem = {
        // ...CHAT_ITEM_RECORD,
        ...createdNew,
        sn: createdNew.conversationid,
        channels: {
          email: createdNew.guest_email,
          phone_number: createdNew.guest_phone,
          whatsapp_phone_number: createdNew.whatsapp_phone_number,
        },
        conversation_memo: createdNew.remark,
        // lasttime: createdNew.session_creatime,
        show_default: createdNew.remark || createdNew.guest_name || createdNew.guest_phone || createdNew.guest_email || createdNew.whatsapp_phone_number || '',
      }
    }
    if (isEmpty(body.conversationid)) {
      // const newChat = _list.find((item) => item.sn === createdNew.conversationid)
      if (!isEmpty(createdNew)) {
        buildChatItem = {
          ...CHAT_ITEM_RECORD,
          ...buildChatItem,
          lasttime: createdNew.session_creatime,
        }
        setCurrentConversation(buildChatItem);
        updateConversationItem(buildChatItem);
        addToConversationList([buildChatItem], 'top');
        return ;
      }
      // setCurrentConversation({
      //   ...newChat,
      //   show_default: newChat.conversation_memo || newChat.whatsapp_name || newChat?.channels?.whatsapp_phone_number || newChat?.channels?.phone_number || newChat?.channels?.email || '',
      // })
      return ;
    }
    updateConversationItem(buildChatItem);

  };

  return { openOrderContactConversation, newConversation };
}
