import { useState } from 'react'
import { FloatButton } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { getEmailFetchAction } from '@/actions/EmailActions'
import useAuthStore from '@/stores/AuthStore'
import { MailDownloadIcon } from '@/components/Icons'

const EmailFetch = ({ ...props }) => {
  const { userId, emailList } = useAuthStore((state) => state.loginUser)

  const [getEmailLoading, setEmailLoading] = useState(false)
  const [fetchingText, setFetchingText] = useState('立即收件')
  const handleGetEmail = async () => {
    setEmailLoading(true)
    setFetchingText('正在接收邮件...');
    const ret = await getEmailFetchAction({opi_sn: userId})
    setEmailLoading(false)
    setFetchingText('立即收件');
    // message.info('正在接收邮件...') // .info
  }
  return (
    <FloatButton
      key={'email-fetch'}
      icon={getEmailLoading ? <LoadingOutlined className='text-indigo-500' /> : <MailDownloadIcon className='text-indigo-500' />}
      tooltip={fetchingText}
      onClick={handleGetEmail}
    />
  )

  // return (
  //   <>
  //     <Tooltip key={'email-fetch'} title={fetchingText}>
  //       <Button type='text' onClick={handleGetEmail} icon={getEmailLoading ? <LoadingOutlined className='text-indigo-500' /> : <MailDownloadIcon className='text-indigo-500' />} />
  //     </Tooltip>
  //   </>
  // )
}
export default EmailFetch
