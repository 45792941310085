import { useState, useEffect } from 'react'
import { App, Button, Divider, Avatar } from 'antd'
import { LoadingOutlined, ApiOutlined } from '@ant-design/icons';
import { EditIcon, ReplyIcon, ResendIcon, ShareForwardIcon } from '@/components/Icons'
import { isEmpty, TagColorStyle } from '@/utils/commons'
import EmailEditorPopup from '../Input/EmailEditorPopup'
import DnDModal from '@/components/DndModal'
import useStyleStore from '@/stores/StyleStore'
import { useEmailDetail,  } from '@/hooks/useEmail';
import { EMAIL_ATTA_HOST } from '@/config';
import EmailBindFormModal from './EmailBind';

/**
 * @property {*} emailMsg - 邮件数据. { conversationid, actionId, order_opi, coli_sn, msgOrigin: { from, to, id, email: { subject, mai_sn, } } }
 */
const EmailDetail = ({ open, setOpen, emailMsg={}, disabled=false, ...props }) => {

  // console.log('emailDetail', emailMsg);

  const {notification, message} = App.useApp()

  const { conversationid, actionId, order_opi, coli_sn } = emailMsg
  const { mai_sn, id } = emailMsg.msgOrigin?.email || emailMsg.msgOrigin || {}
  const mailID = mai_sn || id
  const [initialPosition, setInitialPosition] = useState({})
  const [initialSize, setInitialSize] = useState({})
  function onHandleMove(e) {
    const { top, left, width, height } = e
    setInitialPosition({ top, left })
  }
  function onHandleResize(e) {
    const { top, left, width, height } = e
    setInitialPosition({ top, left })
    setInitialSize({ width, height })
  }

  const [action, setAction] = useState('')

  const [openEmailEditor, setOpenEmailEditor] = useState(false)
  const [fromEmail, setFromEmail] = useState('')
  const onOpenEditor = (msgOrigin, action) => {
    const { from, to } = msgOrigin
    setOpenEmailEditor(true)
    setFromEmail(action === 'edit' ? from : to)
    setAction(action)
    setOpen(false)
  }

  const [mobile] = useStyleStore((state) => [state.mobile])

  const { loading, mailData, orderDetail, postEmailResend } = useEmailDetail(mailID)
  const [showBindBtn, setShowBindBtn] = useState(false);
  useEffect(() => {
    setShowBindBtn(isEmpty(mailData.info?.MAI_COLI_SN))
    return () => {}
  }, [mailData.info?.MAI_COLI_SN])

  const handleResend = async () => {
    if (isEmpty(mai_sn)) {
      return false
    }
    try {
      await postEmailResend({ mai_sn, conversationid, actionId })
      setOpen(false)
    } catch (err) {
      notification.error({
        message: "请求失败",
        description: err.message,
        placement: "top",
        duration: 3,
      });
    }
  }

  /**
   * 根据状态, 显示操作
   * * 已保存: []
   * * 已发送: 回复, 转发
   * * 失败: 重发
   * todo: disabled 不显示
   */
  const ActionBtns = ({className, ...props}) => {
    const { status } = mailData.info

    let btns = []

    // 没有关联订单的邮件`绑定订单`
    if (showBindBtn) {
      btns.push(<EmailBindFormModal key={'bind'} onBoundSuccess={() => setShowBindBtn(false)} {...{conversationid, mai_sn, showBindBtn}} />)
      btns.push(<Divider key='divider1' type='vertical' />);
    }

    switch (status) {
      case 'accepted':
        break
      case 'sent':
      case '': // 接收的邮件没有发送状态
        btns.push(
          <Button key={'reply'} onClick={() => onOpenEditor(emailMsg.msgOrigin, 'reply')} size='small' type='text' icon={<ReplyIcon className='text-indigo-500' />}>
            回复
          </Button>
        )
        btns.push(
          <Button key={'forward'} onClick={() => onOpenEditor(emailMsg.msgOrigin, 'forward')} size='small' type='text' icon={<ShareForwardIcon className='text-primary' />}>
            转发
          </Button>
        )
        break
      case 'failed':
        btns.push(
          <Button key={'resend'} onClick={() => handleResend()} size='small' type='text' icon={<ResendIcon className='text-orange-500' />}>
            重发
          </Button>
        )
        btns.push(
          <Button key={'edit'} onClick={() => onOpenEditor({...(emailMsg.msgOrigin || {}), content: mailData.content}, 'edit')} size='small' type='text' icon={<EditIcon className='text-indigo-500' />}>
            编辑
          </Button>
        )
        break
      default:
        break
    }

    return (
      <div className={`flex justify-end items-center w-full ${className || ''}`}>
        {btns}
      </div>
    )
  }

  return (
    <>
      <DnDModal
        open={open}
        setOpen={setOpen}
        title={
          <>
            {loading ? <LoadingOutlined className='mr-1' /> : null}
            {mailData.info?.MAI_Subject || emailMsg?.msgOrigin?.email?.subject}
          </>
        }
        initial={{ width: window.innerWidth - 740, height: window.innerHeight - 100, left: 300 + 24, top: 74 }}
        onMove={onHandleMove}
        onResize={onHandleResize}
        footer={<ActionBtns className='w-full !justify-start' />}>
        <div className='email-container flex flex-col gap-2 *:p-2 *:rounded-sm *:border-b *:border-gray-200 *:shadow-1md'>
          <div className=' font-bold'>{mailData.info?.MAI_Subject || emailMsg?.msgOrigin?.subject}</div>

          <div>
            <div className={['flex flex-wrap justify-end', window.innerWidth < 600 ? 'flex-col' : 'flex-row '].join(' ')}>
              <div className=' grow shrink basis-0 flex flex-wrap gap-2 mb-2 items-center'>
                <Avatar className='' style={TagColorStyle(mailData.info?.MAI_From, true)}>
                  {(mailData.info?.MAI_From || '').substring(0, 1)}
                </Avatar>
                <div className=' flex flex-col '>
                  {/* <span className=' font-bold text-base'>{mailData.info?.fromName}</span> */}
                  <span className='text-neutral-500 text-wrap break-words break-all '>{mailData.info?.MAI_From}</span>
                </div>
              </div>
              <div className=' shrink-0 flex flex-col justify-start gap-1 items-end'>
                <ActionBtns />
                <div className='text-xs '>{mailData.info?.MAI_SendDate || emailMsg.localDate}</div>
              </div>
            </div>
            <div className='text-sm'>
              <span className='text-neutral-500 pr-2'>收件人:</span>
              {mailData.info?.MAI_To}
            </div>
            {mailData.info?.cc && (
              <div className='text-sm'>
                <span className='text-neutral-500 pr-2'>抄送:</span>
                {mailData.info.cc}
              </div>
            )}
            {mailData.info?.bcc && (
              <div className='text-sm'>
                <span className='text-neutral-500 pr-2'>密送:</span>
                {mailData.info.bcc}
              </div>
            )}
            {mailData.attachments.length > 0 && (
              <div className='mt-2 *:ml-2'>
                <span>共{mailData.attachments.length}个附件</span>
                <div className='flex flex-wrap gap-2'>
                {mailData.attachments.map((atta) => (
                  <a href={`${EMAIL_ATTA_HOST}${atta.ATI_ServerFile}`} key={atta.ATI_SN} target='_blank' rel='noreferrer'>
                    {atta.ATI_Name}
                  </a>
                ))}
                </div>
              </div>
            )}
            <Divider className='my-2' />
            <div className='mt-2 whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: mailData.content }}></div>
          </div>
        </div>
      </DnDModal>
      <EmailEditorPopup
        open={openEmailEditor}
        setOpen={setOpenEmailEditor}
        fromEmail={fromEmail}
        fromUser={mailData.info?.MAI_OPI_SN || order_opi}
        fromOrder={mailData.info?.MAI_COLI_SN || coli_sn}
        conversationid={conversationid}
        oid={orderDetail.order_no}
        customerDetail={orderDetail.customerDetail}
        // emailMsg={ReferEmailMsg}
        quoteid={mailID}
        initial={{ ...initialPosition, ...initialSize }}
        mailData={mailData}
        action={action}
        key={`email-detail-inner-${action}-popup_${mailID}`}
      />
    </>
  )
}
export default EmailDetail
