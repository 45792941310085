import { createContext, useEffect, useState } from 'react';
import {} from 'antd';
import Modal from '@dckj/react-better-modal';
import '@dckj/react-better-modal/dist/index.css';
import { isEmpty } from '@/utils/commons';
import useStyleStore from '@/stores/StyleStore';

const DnDModal = ({ children, open, setOpen, onCancel, onMove, onResize, initial = {}, title, footer=null, ...props }) => {
  // const [open, setOpen] = useState(false);
  function onHandleMove(e) {
    // console.log(e, '--->>> onHandleMove');
    if (typeof onMove === 'function') {
      onMove(e);
    }
  }
  function onHandleResize(e) {
    // console.log(e, '--->>> onHandleResize');
    if (typeof onResize === 'function') {
      onResize(e);
    }
  }

  function onHandleOk() {
    // console.log('onOk callback');
  }

  function onHandleCancel() {
    // console.log('onCancel callback');
    if (typeof onCancel === 'function') {
      onCancel();
    }
    setOpen(false);
  }
  function onStageChange({ state, target }) {
    // console.log(state);
  }
  const [mobile] = useStyleStore((state) => [state.mobile]);

  return (
    <Modal
      visible={open}
      keyboard={false}
      draggable
      resizable
      mask={false}
      maskClosable={false}
      // theme='dark'
      // className={'!border !border-solid !border-indigo-500 rounded !p-2' }
      titleBarClassName='!bg-neutral-100 !rounded !rounded-b-none !border-none !p-3 !font-bold !text-slate-600'
      contentClassName='!p-2'
      footerClassName='!p-2'
      className={`!rounded-t !rounded-b-none !border !border-solid !shadow-heavy ${props.rootClassName}`}
      zIndex={2}
      initialWidth={(mobile ? window.innerWidth : (initial.width ?? 680))} // window.innerWidth < 680
      initialHeight={(mobile ? window.innerHeight : (initial.height ?? 600))} // window.innerHeight < 700
      initialTop={mobile ? 0 : (initial.top ?? 74)}
      initialLeft={mobile ? 0 : (initial.left ?? (window.innerWidth - 300))}
      title={title}
      minimizeButton={<></>}
      onMove={onHandleMove}
      onResize={onHandleResize}
      onCancel={onHandleCancel}
      // onOk={onHandleOk}
      onStageChange={onStageChange}
      footer={footer}
      {...props}
      {...(mobile ? { maximizeButton: <></> } : {})}>
      <>{children}</>
    </Modal>
  );
};
export default DnDModal;
