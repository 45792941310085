import { useEffect, useCallback, useState } from 'react'
import { Space, App, Button, Spin, QRCode } from 'antd'
import { ReloadOutlined, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'
import useAuthStore from '@/stores/AuthStore'
import { fetchQRCode } from '@/actions/WaiAction'
import useConversationStore from '@/stores/ConversationStore'
import { isEmpty } from '@/utils/commons'

const connectionStateMappedQRCodeState = {
  open: 'scanned',
  offline: 'expired',
}

const WAIQRCode = ({ ...props }) => {
  const { notification, message } = App.useApp()
  const { whatsAppNo } = useAuthStore((state) => state.loginUser)

  const [qrCode, setQRCode] = useState('expired')
  const [qrStatus, setQRStatus] = useState('expired') // expired, loading, scanned
  const handelGernaterQR = useCallback(() => {
    setQRStatus('loading')
    fetchQRCode(whatsAppNo)
      .then((r) => {
        // setQRCode(r.result.qr)
        // setQRStatus('active')
        // setTimeout(() => {
        //   // 模拟扫码登录成功，实际要请求后端状态来确定
        //   setQRStatus('scanned')
        // }, 3000);
      })
      .catch((ex) => {
        setQRStatus('expired')
        console.error(ex)
        notification.warning({
          placement: 'top',
          message: ex.message,
          // description: ex.message,
        })
      })
  }, [])

  const [wai] = useConversationStore((state) => [state.wai])
  useEffect(() => {
    if (isEmpty(wai)) {
      setQRCode('expired')
      setQRStatus('expired')
      return () => {}
    }
    if (wai.qr) {
      setQRCode(wai.qr)
    }
    setQRStatus(wai.status === '' ? 'active' : connectionStateMappedQRCodeState[wai.status])

    return () => {}
  }, [wai])
  const customStatusRender = (info) => {
    switch (info.status) {
      case 'expired':
        return (
          <div>
            <ExclamationCircleFilled className='text-orange-500' />
            已过期
            <p>
              <Button type='link' onClick={handelGernaterQR}>
                <ReloadOutlined /> {info.locale?.refresh}
              </Button>
            </p>
          </div>
        )
      case 'loading':
        return (
          <Space direction='vertical'>
            <Spin />
            <p>Loading...</p>
          </Space>
        )
      case 'scanned':
        return (
          <div>
            <CheckCircleFilled
              style={{
                color: 'green',
              }}
            />
            {' ' + whatsAppNo}已登录
            {/* {info.locale?.scanned} */}
          </div>
        )
      default:
        return null
    }
  }

  return <QRCode size={264} value={qrCode} errorLevel='L' status={qrStatus} statusRender={customStatusRender} />
}
export default WAIQRCode
