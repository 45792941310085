/* eslint-disable no-undef */
// export const API_HOST = 'https://p9axztuwd7x8a7.mycht.cn/whatsapp_qqs'
// export const WS_URL = 'wss://p9axztuwd7x8a7.mycht.cn/whatsapp_qqs'; // test:
//
// export const API_HOST = 'https://p9axztuwd7x8a7.mycht.cn/whatsapp_144';
// export const WS_URL = 'wss://p9axztuwd7x8a7.mycht.cn/whatsapp_144'; // prod: Slave

// debug:
// export const API_HOST = 'http://202.103.68.144:8889/v2';
// export const WS_URL = 'ws://202.103.68.144:8889';
// export const EMAIL_HOST = 'http://202.103.68.231:888/service-mail';
// export const WAI_HOST = 'http://47.83.248.120/api/v1'; // 香港服务器
export const WAI_HOST = 'http://47.254.53.81/api/v1'; // 美国服务器
// export const WAI_HOST = 'http://localhost:3031/api/v1'; // 美国服务器
export const WAI_SERVER_KEY = 'G-STR:WAI_SERVER'

export const EMAIL_ATTA_HOST = 'https://p9axztuwd7x8a7.mycht.cn/attatchment'; // 邮件附件
// prod:
// export const WAI_HOST = 'https://wai-server-qq4qmtq7wc9he4.mycht.cn/api/v1';
export const EMAIL_HOST = 'https://p9axztuwd7x8a7.mycht.cn/mail-server/service-mail';
export const API_HOST = 'https://p9axztuwd7x8a7.mycht.cn/whatsapp_server/v2';
export const WS_URL = 'wss://p9axztuwd7x8a7.mycht.cn/whatsapp_server'; // prod:
export const VONAGE_URL = 'https://p9axztuwd7x8a7.mycht.cn/vonage-server'; // 语音和视频接口:

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const OSS_URL_CN = 'https://haina-sale-system.oss-cn-shenzhen.aliyuncs.com/WAMedia/';
export const OSS_URL_AP = 'https://hiana-crm.oss-ap-southeast-1.aliyuncs.com/WAMedia/';
export const OSS_URL = OSS_URL_AP;

export const DEFAULT_CHANNEL = 'waba'; // 默认渠道 waba email wa
export const DEFAULT_WABA = '+8617607730395';

const __BUILD_VERSION__ = `__BUILD_VERSION__`.replace(/"/g, '')
const __BUILD_DATE__ = `__BUILD_DATE__`;

export const BUILD_VERSION = process.env.NODE_ENV === 'production' ? __BUILD_VERSION__ : process.env.NODE_ENV;
export const BUILD_DATE = process.env.NODE_ENV === 'production' ? __BUILD_DATE__ : new Date().toLocaleString();
