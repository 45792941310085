import { useEffect, useCallback } from 'react'
import { Row, Col, Space, Input, Descriptions, Avatar, Tag, Divider, List, App, Button, Flex, Select, Form, Typography, Tooltip, Result } from 'antd'
import { UserOutlined, InfoCircleOutlined } from '@ant-design/icons'
import useAuthStore from '@/stores/AuthStore'
import { Conditional } from '@/components/Conditional'
import { PERM_USE_WHATSAPP } from '@/stores/AuthStore'
import { usingStorage } from '@/utils/usingStorage';

import { isEmpty } from '@/utils/commons'
import { WAI_SERVER_KEY } from '@/config';
import WAIQRCode from './WAIQRCode';


function Profile() {
  const { waiServer } = usingStorage(WAI_SERVER_KEY)
  const { notification } = App.useApp()
  const [wabaForm] = Form.useForm()

  const [loginUser, setWhatsAppProfile, isPermitted] = useAuthStore((state) => [
    state.loginUser, state.setWhatsAppProfile, state.isPermitted
  ])

  useEffect(() => {
    // 测试错误捕获：
    // throw new Error('💥 CABOOM 💥')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handelUpdateWABA = useCallback(() => {
    const formValues = wabaForm.getFieldsValue()
    setWhatsAppProfile(loginUser.userId, formValues.whatsAppBusiness, formValues.whatsAppNo)
      .then(() => {
        notification.info({
          message: 'Notification',
          description: 'WhatsApp 配置成功',
          placement: 'top',
        })
      })
      .catch((ex) => {
        notification.error({
          message: 'Notification',
          description: ex.message,
          placement: 'top',
          duration: 4,
        })
      })
  }, [])

  return (
    <>
      <Row>
        <Col span={6} offset={6}>
          <Descriptions title='个人资料' layout='vertical' column={1}>
            <Descriptions.Item label='名字'>
              <Space size='middle'>
                <Avatar src={loginUser.avatarUrl}>{loginUser.username.substring(1)}</Avatar>
                {loginUser.username}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label='HT 账号'>
              {loginUser.accountList?.map((a) => {
                return (
                  <Tag key={a.OPI_Code} icon={<UserOutlined />} bordered={false}>
                    {a.OPI_Code}
                  </Tag>
                )
              })}
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <Space>
                  <Tooltip title='你所属的业务部门'>
                    <InfoCircleOutlined />
                  </Tooltip>
                  <span>商业号身份</span>
                </Space>
              }>
              <Form layout='vertical' className='w-3/5' form={wabaForm} initialValues={{ whatsAppBusiness: loginUser.whatsAppBusiness, whatsAppNo: loginUser.whatsAppNo }}>
                <Form.Item name='whatsAppBusiness'>
                  <Select
                    options={[
                      {
                        value: '+8617607730395',
                        label: 'GH 顾问(+8617607730395)',
                      },
                      {
                        value: '+8618174165365',
                        label: '国际事业部(+8618174165365)',
                      },
                      {
                        value: 'GH 客运',
                        label: 'GH 客运(无)',
                        disabled: true,
                      },
                      {
                        value: 'GH 客服',
                        label: 'GH 客服(无)',
                        disabled: true,
                      },
                      {
                        value: 'CT 事业部',
                        label: 'CT 事业部(无)',
                        disabled: true,
                      },
                      {
                        value: '花梨鹰事业部',
                        label: '花梨鹰事业部(无)',
                        disabled: true,
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label='WhatsApp'
                  name='whatsAppNo'>
                  <Input placeholder='e.g. 8618212345678' />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' onClick={handelUpdateWABA}>
                    保存
                  </Button>
                </Form.Item>
              </Form>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={6}>
          <List
            header={<div>邮箱</div>}
            dataSource={loginUser.emailList}
            renderItem={(item) => {
              const isDefault = item.default ? <Tag color='green'>默认</Tag> : null
              const isBackup = item.backup ? <Tag color='cyan'>备用</Tag> : null
              return (
                <List.Item>
                  {item.email} {isDefault}
                  {isBackup}
                </List.Item>
              )
            }}
          />
        </Col>
      </Row>
      <Divider orientation='left'></Divider>
      <Row>
        <Col span={6} offset={6}>
          <Typography>
            <Typography.Title level={2}>在系统上使用 WhatsApp</Typography.Title>
            <Typography.Paragraph>
              <ul>
                <li>点击刷新二维码➡️</li>
                <li>在手机上打开 WhatsApp</li>
                <li>点击“已关联的设备”，再点击“关联新设备”</li>
                <li>将手机对准屏幕扫描二维码➡️</li>
              </ul>
            </Typography.Paragraph>

            <Typography.Paragraph>
            WhatsApp 服务：{isEmpty(waiServer) ? '请联系你的主管申请开通' : waiServer}
            </Typography.Paragraph>
          </Typography>
        </Col>
        <Col span={6}>
          <Flex gap='middle' vertical justify={'center'} align={'center'}>
            <Conditional
              condition={isPermitted(PERM_USE_WHATSAPP)}
              whenTrue={<WAIQRCode />}
              whenFalse={
                <Result
                  status='403'
                />
              }
            />
          </Flex>
        </Col>
      </Row>
    </>
  )
}

export default Profile
