const persistObject = {}

/**
 * G-INT:USER_ID -> userId = 456
 * G-STR:LOGIN_TOKEN -> loginToken = 'E6779386E7D64DF0ADD0F97767E00D8B'
 * G-JSON:LOGIN_USER -> loginUser = { username: 'test-username' }
 */
export function usingStorage() {

  const getStorage = () => {
    if (import.meta.env.DEV && window.localStorage) {
      return window.localStorage
    } else if (window.sessionStorage) {
      return window.sessionStorage
    } else {
      console.error('browser not support localStorage and sessionStorage.')
    }
  }

  const setProperty = (key, value) => {
    const webStorage = getStorage()
    const typeAndKey = key.split(':')
    if (typeAndKey.length === 2) {
      const propName = camelCasedWords(typeAndKey[1])
      persistObject[propName] = value
      if (typeAndKey[0] === 'G-JSON') {
        webStorage.setItem(key, JSON.stringify(value))
      } else {
        webStorage.setItem(key, value)
      }
    }
  }

  // USER_ID -> userId
  const camelCasedWords = (string) => {
    if (typeof string !== 'string' || string.length === 0) {
      return string;
    }
    return string.split('_').map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      }
    }).join('')
  }

  if (Object.keys(persistObject).length == 0) {

    const webStorage = getStorage()

    for (let i = 0; i < webStorage.length; i++) {
      const key = webStorage.key(i)
      const typeAndKey = key.split(':')

      if (typeAndKey.length === 2) {
        const value = webStorage.getItem(key)
        const propName = camelCasedWords(typeAndKey[1])
        if (typeAndKey[0] === 'G-INT') {
          persistObject[propName] = parseInt(value, 10)
        } else if (typeAndKey[0] === 'G-JSON') {
          try {
            persistObject[propName] = JSON.parse(value)
          } catch (e) {
            // 如果解析失败，保留原始字符串值
            persistObject[propName] = value
            console.error('解析 JSON 失败。')
          }
        } else {
          persistObject[propName] = value
        }
      }
    }
  }

  return {
    ...persistObject,
    setStorage: (key, value) => {
      setProperty(key, value)
    },
    clearStorage: () => {
      getStorage().clear()
      Object.assign(persistObject, {})
    }
  }
}
