import { App, Upload, Button, message, Tooltip } from 'antd';
import { useState } from 'react';
import { FileAddOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { API_HOST, OSS_URL as aliOSSHost } from '@/config';
import { whatsappSupportFileTypes, uploadProgressSimulate } from '@/channel/bubbleMsgUtils';
import { isEmpty, sanitizeFilename } from '@/utils/commons';
// import useConversationStore from '@/stores/ConversationStore';

const ImageUpload = ({ disabled, invokeUploadFileMessage, invokeSendUploadMessage }) => {

  const { message: appMessage } = App.useApp();

  // const setComplexMsg = useConversationStore(state => state.setComplexMsg);
  // const complexMsg = useConversationStore(state => state.complexMsg);

  const [fileObj, setFileObj] = useState();
  const beforeUpload = async (file) => {
    const fileTypeSupport = Object.keys(whatsappSupportFileTypes).find((msgType) => whatsappSupportFileTypes[msgType].types.includes(file.type));
    if (isEmpty(fileTypeSupport)) {
      appMessage.warning('不支持的文件格式');
      return false;
    }
    const waFile = whatsappSupportFileTypes[fileTypeSupport];
    if (file.size > waFile.size) {
      appMessage.warning('超过大小限制');
      return false;
    }
    // 使用 FileReader 读取文件对象
    const reader = new FileReader();
    const lastDotIndex = file.name.lastIndexOf('.');
    const suffix = file.name.slice(lastDotIndex+1).toLocaleLowerCase();
    const baseName = file.name.slice(0, lastDotIndex);
    const rename = sanitizeFilename(baseName) + '-' + Date.now() + '.' + suffix;
    const dataUri = aliOSSHost + rename;
    // 读取完毕后获取结果
    reader.onload = (event) => {
      const previewSrc = event.target.result;
      const msgObj = {
        type: fileTypeSupport,
        name: file.name,
        // status: 'loading',
        data: { uri: previewSrc, dataUri: '', link: '', width: '100%', height: 150, loading: uploadProgressSimulate() },
        id: uuid(),
      };
      file.msgData = msgObj;
      setFileObj(msgObj);
      invokeUploadFileMessage(msgObj);
    };
    // 把文件对象作为一个 dataURL 读入
    reader.readAsDataURL(file);
    return new File([file], rename, { type: file.type })
  };
  const uploadProps = {
    name: 'file',
    action: `${API_HOST}/WAFileUpload`,
    headers: {
      'X-Requested-With': null
    },
    showUploadList: false,
    beforeUpload,
    maxCount: 1,
    accept: Object.values(whatsappSupportFileTypes).reduce((r, c) => r.concat(c.types), []).join(',')
  };
  return (
    <Upload
      {...uploadProps}
      onChange={({file}) => {
        if (file.status === 'done') {
          const { file_url } = file.response.result;
          invokeSendUploadMessage({...fileObj, data: { ...fileObj.data, link: file_url, dataUri: file_url, uri: file_url, loading: 0 }});
        }
        if (file.status === 'error') {
          message.error(`添加失败`);
        }
      }}
    >
      <Tooltip title={<><div>webp(100K)</div><div>图片(5M)</div><div>视频(16M)</div><div>语音(16M)</div><div>附件(100M)</div></>} >
      <Button key={'addPic'} type='text' disabled={disabled} icon={<FileAddOutlined />} size={'middle'} className='text-primary rounded-none' />
      </Tooltip>
    </Upload>
  );
};
export default ImageUpload;
